import { Grid } from "@mui/material"
import React, { useCallback } from "react"
import FromDateToDateFilterSecond from "./items/FromDateToDateFilterSecond"
import { useFormik } from "formik"
import SingleBlockFilter from "./items/SingleBlockFilter"
import SingleObjectFilter from "./items/SingleObjectFilter"
import ExpenseReasonFilter from "./items/ExpenseReasonFilter"
import FormSelectChangeFnField from "../form/FormSelectChangeFnField"
import PaymentTypeFilter from "./items/PaymentTypeFilter"
const ExpenseFilterComponent = () => {
	const formik = useFormik({
		initialValues: {
			sum: "",
			payment_type: "",
			comment: "",
			object_id: "",
			block_id: ""
		},
	
	})
	const handleObjectChange = (value) => {
		formik.setFieldValue("object_id", value, true)
		formik.setFieldValue("block_id", "", true)
	}
	return (
		<div className="mb-1 pb-0 pt-1 w-full mt-1 flex items-center">
			<Grid
				container
				spacing={{ xs: 2, sm: 2, lg: 2 }}
				rowSpacing={1}
				columns={{ xs: 18, sm: 18, lg: 18 }}
			>
				<Grid item={true} lg={3} sm={6} xs={12}>
					<SingleObjectFilter
					fieldName="object_id"
					path="/dictionary/objects2" 
					formik={formik}
					changeFn={handleObjectChange}
					/>
				</Grid>
				{formik.values.object_id && (
				<Grid item={true} lg={3} sm={6} xs={12}>
					<SingleBlockFilter 
					fieldName="block_id"
					formik={formik}
					path={`admin/block/index/${formik.values.object_id}`}
					pathChangeable={true} />
				</Grid>
				)}
				<Grid item={true} lg={3} sm={6} xs={12}>
					<PaymentTypeFilter
					labelLang="expense.modal.fields.paymentType"
					fieldName="payment_type"
					path="/dictionary/types" 
					formik={formik}
				/>
				</Grid>
					<Grid item={true} lg={3} sm={6} xs={12}>
					<ExpenseReasonFilter
					fieldName="reason_id"
					path="/reasons" 
					formik={formik}
					/>
				</Grid>
				<Grid style={{display: "flex", alignItems: "center", gap: "10px"}} item={true} lg={5} sm={6} xs={12}>
					<FromDateToDateFilterSecond />
				</Grid>
			</Grid>
		</div>
	)
}

export default ExpenseFilterComponent