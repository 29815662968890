import moment from "moment"
import template from "../template/Payment.docx"
import templateIsfan from "../template/Payment.docx"
import "moment/locale/ru"
import { numericFormatter } from "react-number-format"
import Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import { saveAs } from "file-saver"
const localeMoment = moment
localeMoment.locale("ru")

const generateChequeFromData = async ({
	paymentId,
	IsSubmittingFn = () => {},
	axiosPrivate = () => {},
	sendNotification = () => {}
}) => {
	if (paymentId) {
		try {
			IsSubmittingFn(true)
			const response = await axiosPrivate.get(
				`/dictionary/payment/${paymentId}`,
				{
					headers: { "Content-Type": "application/json" }
				}
			)
			if (response.data && response.data.status && response.data.data) {
				await generateDocument(response.data.data)
				IsSubmittingFn(false)
			}
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
			IsSubmittingFn(false)
		}
	}
}
const generateDocument = async (responseData) => {
		const priceFormat = responseData?.payment?.sum ? numericFormatter(responseData?.payment?.sum, {
				decimalScale: 3,
				thousandSeparator: " ",
				allowNegative: false,
				fixedDecimalScale: 0
		  })
		: "0"
		const leftDateMonth = responseData?.payment?.date &&
		localeMoment(responseData?.payment?.date).format("MMMM")
		let templateData = {
		company:
			responseData?.payment?.contract?.homes?.blocks?.objects?.companies?.name,
		id: responseData?.payment?.payment_number,
		date:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("DD"),
		month:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("MM"),
		year:
			responseData?.payment?.date &&
			localeMoment(responseData?.payment?.date).format("YYYY"),
		custom: `${responseData?.payment?.contract2?.custom?.surname} ${responseData?.payment?.contract2?.custom?.name} ${responseData?.payment?.contract2?.custom?.middlename}`,
		contractName: responseData?.payment?.contract?.name,
		contractSum: responseData?.payment?.contract?.sum  ? numericFormatter(responseData?.payment?.contract?.sum, {
			decimalScale: 3,
			thousandSeparator: " ",
			allowNegative: false
	  })
	: "0",
		contractLeft: responseData?.payment?.contract?.left ? numericFormatter(responseData?.payment?.contract?.left, {
			decimalScale: 3,
			thousandSeparator: " ",
			allowNegative: false
	  })
	: "0",
		contractLeftMonth: leftDateMonth,
		contractMonthlyLeft: responseData?.oylikqarz?.list_sum_left !== null ? responseData?.oylikqarz?.list_sum_left ? numericFormatter(responseData?.oylikqarz?.list_sum_left, {
			decimalScale: 3,
			thousandSeparator: " ",
			allowNegative: false,
			fixedDecimalScale: 0
	  })
	: "0" : "0",
		contractId: responseData?.payment?.contract?.id,
		contractDate: responseData?.payment?.contract?.date && `${localeMoment(responseData?.payment?.date).format("DD")}.${localeMoment(responseData?.payment?.date).format("MM")}.${localeMoment(responseData?.payment?.date).format("YYYY")}`,
		objectName: responseData?.payment?.contract?.homes?.blocks?.objects?.name,
		blockName: responseData?.payment?.contract?.homes?.blocks?.name.split(" ")[0],
		homeNumber: responseData?.payment?.contract?.homes?.number,
		homeStage: responseData?.payment?.contract?.homes?.stage,
		paymentPrice: priceFormat,
		paymentPriceText: responseData?.numbertext,
		paymentTypeNaqd: responseData?.payment?.types?.id == 1 ? priceFormat :"",
		paymentTypePtP: responseData?.payment?.types?.id == 2 ? priceFormat :"",
		paymentTypeBank: responseData?.payment?.types?.id == 3 ? priceFormat :"",
		paymentTypeUzcard: responseData?.payment?.types?.id == 4 ? priceFormat :"",
		paymentTypeAkt: responseData?.payment?.types?.id == 5 ? priceFormat :"",
		paymentTypeHumo: responseData?.payment?.types?.id == 6 ? priceFormat :"",
		paymentTypeUsd: responseData?.payment?.types?.id == 7 ? priceFormat :""
	}
	try {
		let response = await fetch(templateIsfan)
		let data = await response.arrayBuffer()

		let zip = PizZip(data)

		let templateDoc = new Docxtemplater(zip, {
			paragraphLoop: true,
			linebreaks: true
		})

		templateDoc.render(templateData)

		let generatedDoc = templateDoc.getZip().generate({
			type: "blob",
			mimeType:
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			compression: "DEFLATE"
		})

		saveAs(generatedDoc, `To'lov.docx`)
	} catch (error) {
		console.log("Error: " + error)
	}
}

export default generateChequeFromData
