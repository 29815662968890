import { Grid } from "@mui/material"
import FromDateToDateFilter from "./items/FromDateToDateFilter"
import SingleBlockFilter from "./items/SingleBlockFilter"
import SingleObjectFilter from "./items/SingleObjectFilter"
import { useFormik } from "formik"
import PaymentTypeFilter from "./items/PaymentTypeFilter"
const PaymentFiltersComponent = () => {
	const formik = useFormik({
		initialValues: {
			sum: "",
			payment_type: "",
			comment: "",
			object_id: "",
			block_id: ""
		},
	
	})
	const handleObjectChange = (value) => {
		formik.setFieldValue("object_id", value, true)
		formik.setFieldValue("block_id", "", true)
	}
	return (
		<div className="w-full mt-1 flex items-center">
			<Grid
				container
				spacing={{ xs: 2, sm: 2, lg: 2 }}
				rowSpacing={1}
				columns={{ xs: 20, sm: 20, lg: 18 }}
			>
				<Grid item={true} lg={2.5} sm={6} xs={12}>
					<SingleObjectFilter
					fieldName="object_id"
					path="/dictionary/objects2" 
					formik={formik}
					changeFn={handleObjectChange}
						/>
				</Grid>
					
				{formik.values.object_id && (
					<Grid item={true} lg={3} sm={6} xs={12}>
						<SingleBlockFilter 
						fieldName="block_id"
						formik={formik}
						path={`admin/block/index/${formik.values.object_id}`}
						pathChangeable={true} />
					</Grid>
					)}
				<Grid item={true} lg={2.5} sm={6} xs={12}>
					<PaymentTypeFilter
					labelLang="common.table.typeId"
					fieldName="payment_type"
					path="/dictionary/types" 
					formik={formik}
						/>
				</Grid>
			</Grid>
		</div>
	)
}

export default PaymentFiltersComponent
