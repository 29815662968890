import { Button } from "@mui/material"
import SimpleClickableSearchTable from "components/ui/tables/SimpleClickableSearchTable"
import useTopPanel from "hooks/useTopPanel"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import MessageDataModal from "./MessageDataModal"
import { messageStatusVariants } from "shared/tableColVariantsList"
import PaymentFiltersComponent from "components/ui/filters/PaymentFiltersComponent"
import SearchInput from "components/SearchInput"
const Message = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [opens, setOpens] = useState(false)
	const [data, setData] = useState([])
	const [expanded, setExpanded] = useState(false)
	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("message.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14">
						<Button
								variant="filterOutlined"
								color="primary"
								startIcon={<i className="bi bi-filter" />}
								className="!mr-2"
								onClick={() => setExpanded((prev) => !prev)}
							>
								{t("common.button.filter")}
						</Button>
						<SearchInput inputKey="name" />
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
							className="!mr-2"
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
				{expanded && (
					<div className="my-shadow-2 rounded-lg px-4 w-full mt-2">
						<PaymentFiltersComponent status />
					</div>
				)}
			</div>

			<div className="component-table-wrapper">
				<SimpleClickableSearchTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="register_sms"
					emitTableColumns={{ open, setOpen }}
					modalDetails = {
						{setOpens, 
						setData}
					}
					tableName="messages"
					headCells={[
						{ code: "id", label: "#" },
						{ code: "custom", label: "Mijoz" },
						{ code: "phone", label: "Telefon raqami" },
						{ code: "created_at", label: "Yuborilgan sana" },
						{ code: "text", numeric: 'ssddfdsad', label: "Xabar matni" },
						{ code: "status", statusMessage: true, label: t("common.table.status") }
					]}
					columns={[
						{
							code: "custom",
							type: "nestedChain",
							childStrings: ["surname", "name", "middlename"]
						},
						{ code: "custom",
							type: "nestedChain",
							childStrings: ["phone"] },
						{ code: "created_at" },
						{ code: "text", type: "textLimit" },
						{
							code: "status",
							type: "customStatus",
							variants: messageStatusVariants,
							indexStatus: 1,
						}
					]}
				/>
			</div>
			{
				opens && (
					<MessageDataModal 
					open={opens}
					setOpen={setOpens}
					data={data}
					/>
				)
			}
		</div>
	)
}

export default Message
