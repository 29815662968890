import { CircularProgress, Fab } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { useEffect, useState } from "react"
import generateChequeFromData from "utils/generateCheque"

const GenerateCheque = ({ id, sum }) => {
	const [canDownload, setCanDownload] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const sendNotification = useNotification()
	const axiosPrivate = useAxiosPrivate()

	useEffect(() => {
		if (sum && parseFloat(sum) === 0) {
			setCanDownload(false)
		}
	}, [])

	return (
		<Fab
			color="info"
			variant="action"
			aria-label="list"
			onClick={() =>
				generateChequeFromData({
					paymentId: id,
					IsSubmittingFn: setIsSubmitting,
					axiosPrivate: axiosPrivate,
					sendNotification: sendNotification
				})
			}
			disabled={isSubmitting || !canDownload}
		>
			{isSubmitting ? (
				<CircularProgress size={15} color="inherit" />
			) : (
				<i className="bi bi-journal-text" />
			)}
		</Fab>
	)
}

export default GenerateCheque
