const MESSAGE_STATUS = {
	SENT: {
		code: 1,
		label: "common.typeList.messageStatusType.active"
	},
	NOTSENT: {
		code: 2,
		label: "common.typeList.messageStatusType.time"
	},
	CANCELLED: {
		code: 3,
		label: "common.typeList.messageStatusType.ordered"
	},
	ISBEINGSENT: {
		code: 4,
		label: "common.typeList.messageStatusType.disabled"
	}
}

export default MESSAGE_STATUS
