import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import SimpleNumberField from "components/ui/simple-fields/numeric-format/SimpleNumberField"
import SimpleRadioGroupField from "components/ui/simple-fields/radio-group/SimpleRadioGroupField"
import SimpleToggleButtonGroup from "components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup"
import useCurrency from "hooks/useCurrency"
import { Fragment, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import CURRENCY_TYPE from "shared/currencyTypeList"
import REPAIR_TYPE from "shared/repairTypeList"
import {
	currencyTypeSelectOptions,
	repairTypeSelectOptions
} from "shared/selectOptionsList"
import { formatMultiplies, formatSingle } from "utils/formatOddDecimal"

const ShaxmatkaMoreHomeDetailsModal = ({ open, setOpen, homeData = {} }) => {
	const { t } = useTranslation()
	const [repairType, setRepairType] = useState(
		homeData?.isrepaired || REPAIR_TYPE.NOT_REPAIRED.code
	)
	const [sellPrice, setSellPrice] = useState(0)
	const [startPrice, setStartPrice] = useState(homeData?.start)
	const [startPricePercentage, setStartPricePercentage] = useState(0)
	const [priceForSquare, setPriceForSquare] = useState(
		homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
			? homeData?.repaired
			: homeData?.norepaired
	)
	const [square, setSquare] = useState(homeData.square)
	const [discount, setDiscount] = useState(0)
	const [discountPercentage, setDiscountPercentage] = useState(0)
	const [additionalPercentage, setAdditionalPercentage] = useState(0)
	const [month, setMonth] = useState(6)
	const [priceForMonth, setPriceForMonth] = useState(0)
	const [currency, setCurrency] = useState(
		homeData?.isvalute || CURRENCY_TYPE.SUM.code
	)
	const { currencyData } = useCurrency()

	const handleChangeRepairType = (value) => {
		setRepairType(value)
		if (value === REPAIR_TYPE.REPAIRED.code) {
			let sellP =
				homeData?.repaired && homeData?.square
					? formatMultiplies([homeData.repaired, homeData.square], 2)
					: 0
			sellP = (sellP * (100 + additionalPercentage)) / 100 + discount
			setSellPrice(sellP)
			setPriceForMonth(formatSingle((sellP - discount - startPrice) / month))
			setStartPricePercentage(
				sellP ? formatSingle((startPrice * 100) / sellP, 3) : 0
			)
			setDiscountPercentage(
				sellP ? formatSingle((discount * 100) / sellP, 3) : 0
			)
			setPriceForSquare(homeData?.repaired)
			setSquare(homeData?.square)
		} else {
			let sellP =
				homeData?.norepaired && homeData?.square
					? formatMultiplies([homeData.norepaired, homeData.square], 2)
					: 0
			sellP = (sellP * (100 + additionalPercentage)) / 100 + discount
			setSellPrice(sellP)
			setPriceForMonth(formatSingle((sellP - discount - startPrice) / month))
			setStartPricePercentage(
				sellP ? formatSingle((startPrice * 100) / sellP, 3) : 0
			)
			setDiscountPercentage(
				sellP ? formatSingle((discount * 100) / sellP, 3) : 0
			)
			setPriceForSquare(homeData?.norepaired)
			setSquare(homeData?.square)
		}
	}

	const handleChangeMonth = (value) => {
		setMonth(parseInt(value))
		setPriceForMonth(
			formatSingle((sellPrice - discount - startPrice) / parseInt(value))
		)
	}

	const handleChangeStartPrice = (value) => {
		setStartPrice(value)
		setStartPricePercentage(
			sellPrice ? formatSingle((value * 100) / sellPrice, 3) : 0
		)
		setPriceForMonth(formatSingle((sellPrice - discount - value) / month))
	}

	const handleChangeStartPricePercentage = (value) => {
		setStartPricePercentage(value)
		setStartPrice(formatSingle((value * sellPrice) / 100))
		setPriceForMonth(
			formatSingle(
				(sellPrice - discount - formatSingle((value * sellPrice) / 100)) / month
			)
		)
	}

	const handleChangeDiscount = (value) => {
		setDiscount(value)
		setDiscountPercentage(
			sellPrice ? formatSingle((value * 100) / sellPrice, 3) : 0
		)
		setPriceForMonth(formatSingle((sellPrice - value - startPrice) / month))
		setPriceForSquare(formatSingle((sellPrice - value) / square))
	}

	const handleChangeDiscountPercentage = (value) => {
		setDiscountPercentage(value)
		setDiscount(formatSingle((value * sellPrice) / 100))
		setPriceForMonth(
			formatSingle(
				(sellPrice - formatSingle((value * sellPrice) / 100) - startPrice) /
					month
			)
		)
		setPriceForSquare(
			formatSingle(
				(sellPrice - formatSingle((value * sellPrice) / 100)) / square
			)
		)
	}

	const handleChangePriceForSquare = (value) => {
		setPriceForSquare(value)
		let sellP = value && square ? formatMultiplies([value, square], 2) : 0
		sellP = (sellP * (100 + additionalPercentage)) / 100 + discount
		setSellPrice(sellP)
		setPriceForMonth(formatSingle((sellP - discount - startPrice) / month))
		setStartPricePercentage(
			sellP ? formatSingle((startPrice * 100) / sellP, 3) : 0
		)
		setDiscountPercentage(sellP ? formatSingle((discount * 100) / sellP, 3) : 0)
	}

	const handleChangeSquare = (value) => {
		setSquare(value)
		let sellP =
			priceForSquare && value ? formatMultiplies([priceForSquare, value], 2) : 0
		sellP = (sellP * (100 + additionalPercentage)) / 100 + discount
		setSellPrice(sellP)
		setPriceForMonth(formatSingle((sellP - discount - startPrice) / month))
		setStartPricePercentage(
			sellP ? formatSingle((startPrice * 100) / sellP, 3) : 0
		)
		setDiscountPercentage(sellP ? formatSingle((discount * 100) / sellP, 3) : 0)
	}

	const handleChangeAdditionalPercentage = (value) => {
		setAdditionalPercentage(value)
		let sellP =
			priceForSquare && square
				? formatMultiplies([priceForSquare, square], 2)
				: 0
		sellP = (sellP * (100 + value)) / 100 + discount
		setSellPrice(sellP)
		setPriceForMonth(formatSingle((sellP - discount - startPrice) / month))
		setStartPricePercentage(
			sellP ? formatSingle((startPrice * 100) / sellP, 3) : 0
		)
		setDiscountPercentage(sellP ? formatSingle((discount * 100) / sellP, 3) : 0)
	}

	const handleCurrencyTypeChange = (value) => {
		setCurrency(value)
		if (currencyData && currencyData.sum) {
			if (value === CURRENCY_TYPE.VALUTE.code) {
				let sellP = formatSingle(sellPrice / currencyData.sum)
				let discountL = formatSingle((sellP * discountPercentage) / 100)
				let startPriceL = formatSingle((sellP * startPricePercentage) / 100)
				setSellPrice(sellP)
				setDiscount(discountL)
				setStartPrice(startPriceL)
				setPriceForMonth(
					formatSingle((sellP - discountL - startPriceL) / month)
				)
				setPriceForSquare(formatSingle((sellP - discountL) / square))
			} else {
				let sellP = formatSingle(sellPrice * currencyData.sum)
				let discountL = formatSingle((discountPercentage * sellP) / 100)
				let startPriceL = formatSingle((sellP * startPricePercentage) / 100)
				setSellPrice(sellP)
				setDiscount(discountL)
				setStartPrice(startPriceL)
				setPriceForMonth(
					formatSingle((sellP - discountL - startPriceL) / month)
				)
				setPriceForSquare(formatSingle((sellP - discountL) / square))
			}
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		let sellP =
			homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
				? homeData?.repaired && homeData?.square
					? formatMultiplies([homeData.repaired, homeData.square], 2)
					: 0
				: homeData?.norepaired && homeData?.square
				? formatMultiplies([homeData.norepaired, homeData.square], 2)
				: 0
		setSellPrice(sellP)
		setPriceForMonth(formatSingle((sellP - +homeData?.start) / month))
		setStartPricePercentage(formatSingle((startPrice * 100) / sellP, 3))
	}, [])

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="order-view-dialog-title">
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				{homeData && Object.keys(homeData).length > 0 && (
					<div>
						<div className="flex items-center justify-end h-14">
							{currency === CURRENCY_TYPE.VALUTE.code && (
								<Fragment>
									<div className="mr-auto">
										{t("common.fields.currency")}:{" "}
										<NumericFormat
											value={currencyData?.sum}
											allowNegative={false}
											displayType={"text"}
											thousandSeparator={" "}
											decimalScale={1}
											className="w-full text-right"
											suffix=" UZS"
										/>
									</div>
								</Fragment>
							)}
							<SimpleToggleButtonGroup
								delay={0}
								duration={0}
								value={currency}
								changeFn={handleCurrencyTypeChange}
								options={currencyTypeSelectOptions}
								size="small"
								fullWidth={true}
								buttonClassName="whitespace-nowrap"
							/>
						</div>
						<div className="mt-2 max-w-[850px]">
							<Grid
								container
								spacing={4}
								rowSpacing={1}
								columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
							>
								<Grid
									item={true}
									md={5}
									sm={6}
									xs={12}
									sx={{ paddingRight: "1rem" }}
								>
									<div>
										<SimpleRadioGroupField
											delay={0}
											duration={0}
											name="isrepaired"
											label={t("common.fields.repairType")}
											value={repairType}
											changeFn={handleChangeRepairType}
											options={repairTypeSelectOptions}
										/>
										<SimpleNumberField
											delay={0}
											duration={0}
											value={month}
											setValue={handleChangeMonth}
											label={t("shaxmatka.moreHomeDetails.month")}
											name="monthValue"
										/>
										<SimpleNumberField
											delay={0}
											duration={0}
											value={additionalPercentage}
											setValue={handleChangeAdditionalPercentage}
											label={t(
												"shaxmatka.moreHomeDetails.additionalPercentage"
											)}
											name="additionalPercentage"
											suffix="%"
										/>
									</div>
								</Grid>

								<Grid
									item={true}
									md={7}
									sm={6}
									xs={12}
									sx={{
										borderLeft: "2px solid #bbbb",
										paddingLeft: "1rem !important"
									}}
								>
									<div>
										<div className="mt-0.5 w-full">
											<Grid
												container
												spacing={1}
												rowSpacing={1}
												columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
											>
												<Grid item={true} md={8} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={startPrice}
														setValue={handleChangeStartPrice}
														label={t(
															"shaxmatka.moreHomeDetails.startPriceAndPercentage"
														)}
														name="startPrice"
													/>
												</Grid>
												<Grid item={true} md={4} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={startPricePercentage}
														setValue={handleChangeStartPricePercentage}
														label=""
														name="startPricePercentage"
														suffix="%"
													/>
												</Grid>
											</Grid>
										</div>
										<div className="mt-0.5 w-full">
											<Grid
												container
												spacing={1}
												rowSpacing={1}
												columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
											>
												<Grid item={true} md={8} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={discount}
														setValue={handleChangeDiscount}
														label={t(
															"shaxmatka.moreHomeDetails.discountAndPercentage"
														)}
														name="discount"
													/>
												</Grid>
												<Grid item={true} md={4} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={discountPercentage}
														setValue={handleChangeDiscountPercentage}
														label=""
														name="discountPercentage"
														suffix="%"
													/>
												</Grid>
											</Grid>
										</div>
										<div className="mt-0.5 w-full">
											<Grid
												container
												spacing={1}
												rowSpacing={1}
												columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
											>
												<Grid item={true} md={8} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={priceForSquare}
														setValue={handleChangePriceForSquare}
														label={
															<Trans i18nKey="shaxmatka.moreHomeDetails.priceForSquare">
																1 m<sup>2</sup> narxi
															</Trans>
														}
														name="priceForSquare"
													/>
												</Grid>
												<Grid item={true} md={4} sm={12} xs={12}>
													<SimpleNumberField
														delay={0}
														duration={0}
														value={square}
														setValue={handleChangeSquare}
														label={t("shaxmatka.moreHomeDetails.square")}
														name="square"
														decimalScale={2}
													/>
												</Grid>
											</Grid>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>

						<div className="my-4">
							<TableContainer className="flex-auto w-full h-full">
								<Table
									sx={{ minWidth: 750, height: "max-content" }}
									aria-labelledby="tableTitle"
								>
									<TableHead>
										<TableRow>
											<TableCell>
												<span>{t("shaxmatka.moreHomeDetails.sellPrice")}</span>
											</TableCell>
											<TableCell>
												<span>{t("shaxmatka.moreHomeDetails.discount")}</span>
											</TableCell>
											<TableCell>
												<span>{t("shaxmatka.moreHomeDetails.startPrice")}</span>
											</TableCell>
											<TableCell>
												<span>{t("shaxmatka.moreHomeDetails.month")}</span>
											</TableCell>
											<TableCell>
												<span>
													{t("shaxmatka.moreHomeDetails.monthlyPrice")}
												</span>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody className="overflow-hidden">
										<TableRow hover tabIndex={-1}>
											<TableCell>
												<NumericFormat
													value={sellPrice}
													allowNegative={false}
													displayType={"text"}
													thousandSeparator={" "}
													decimalScale={1}
													className="w-full text-right"
													suffix={currency === "1" ? " $" : " UZS"}
												/>
											</TableCell>
											<TableCell>
												<NumericFormat
													value={discount}
													allowNegative={false}
													displayType={"text"}
													thousandSeparator={" "}
													decimalScale={1}
													className="w-full text-right"
													suffix={currency === "1" ? " $" : " UZS"}
												/>
												({discountPercentage}%)
											</TableCell>
											<TableCell>
												<NumericFormat
													value={startPrice}
													allowNegative={false}
													displayType={"text"}
													thousandSeparator={" "}
													decimalScale={1}
													className="w-full text-right"
													suffix={currency === "1" ? " $" : " UZS"}
												/>
												({startPricePercentage}%)
											</TableCell>
											<TableCell>{month}</TableCell>
											<TableCell>
												<NumericFormat
													value={priceForMonth}
													allowNegative={false}
													displayType={"text"}
													thousandSeparator={" "}
													decimalScale={1}
													className="w-full text-right"
													suffix={currency === "1" ? " $" : " UZS"}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ShaxmatkaMoreHomeDetailsModal
