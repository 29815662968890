import {
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton
} from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { messageStatusVariants } from "shared/tableColVariantsList"
const MessageDataModal = (props) => {
	const { open, setOpen, data } = props
	const { t } = useTranslation()
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="sm"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">

				<span className="pr-5">{data?.custom?.name} {data?.custom?.surname} {data?.custom?.middlename}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>

			</DialogTitle>
			<Divider />
			<DialogContent style={{padding: "0"}}>
					<Grid
						container
						spacing={{ xs: 0, sm: 0 }}
						rowSpacing={3}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<p className="pl-5 pt-5 text-[#000] font-medium pb-5">Yuborilgan sana:</p>
						<Divider />

						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<p className="pr-5 pt-5 text-[#000] font-medium pb-5">{data?.created_at}</p>
						<Divider />

						</Grid>
						<Grid item={true} sm={6} xs={12}>
							<p className="pl-5 pb-6 text-[#000] font-medium">Status:</p>
							<Divider />
						</Grid>
						<Grid item={true} sm={6} xs={12}>
						<p className="pr-5 mt-[-6px]  pb-6">
								
										<Chip
											label={t(messageStatusVariants.filter((variant) => variant.code === 1)[0]?.label)}
											variant="tableBadge"
											color={messageStatusVariants.filter((variant) => variant.code === 1)[0]?.color}
										/>
								</p>
							<Divider />
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<p className="pl-5 text-[#000] font-medium">Xabar matni</p>
						</Grid>
						<Grid item={true} sm={12} xs={12} style={{marginTop: '-15px'}}>
							<p className="px-5 text-[14px]">{data?.text}</p>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<div className="flex justify-end pb-3 px-4">
							<Button
							variant="outlined"
							color="primary"
							onClick={handleClose}
							>
								{t("contract.modal.cancel.closeWindow")}
							</Button>
							</div>
						</Grid>
						

					</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default MessageDataModal
