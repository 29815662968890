import {
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
// import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
// import FormCurrencyField from "components/ui/form/FormCurrencyField"
// import FormMultilineTextField from "components/ui/form/FormMultilineTextField"
// import FormSelectChangeFnField from "components/ui/form/FormSelectChangeFnField"
import FormTextField from "components/ui/form/FormTextField"
// import FormSelectField from "components/ui/form/FormSelectField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import * as yup from "yup"

const validationSchema = yup.object({
	reason: yup.string().required("Chiqim turi"),
	type: yup.string().default("expenses_outcome")
})

const ReasonAddEdit = (props) => {
	const { open, setOpen, setRefetch, reasonId } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [typesList, setTypesList] = useState([])
	const [hasError, setHasError] = useState(false)

	const formik = useFormik({
		initialValues: {
			reason: "",
			type: "expenses_outcome",
			
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				let newValues = { ...values }
				setIsSubmitting(true)
					const response = await axiosPrivate.post(
						"/reasons",
						JSON.stringify(newValues),
						{ headers: { "Content-Type": "application/json" } }
					)
					if (response.data) {
						console.log(response);
						sendNotification({
							msg: t("expense.modal.alerts.success"),
							variant: "success"
						})
						setIsSubmitting(false)
						handleClose()
						setRefetch(true)
					}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})
	const { isLoading, isFetching } = useQuery({
		queryKey: "reasonTypes",
		queryFn: async function () {
			const response = await axiosPrivate.get("/reasons")
			return response.data.data
		},
		onSuccess: (data) => {
			setTypesList([...data.map((item) => ({ ...item, editable: false }))])
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})
	console.log(typesList);
	const handleChangeEditable = (typeId) => {
		let foundSource = typesList.find((item) => item.id === typeId)
		foundSource.editable = !foundSource.editable
		setTypesList([...typesList])
		console.log(typesList);
	}
	const handleChangeItemLabel = (typeId, newLabel) => {
		let foundSource = typesList.find((item) => item.id === typeId)
		foundSource.name = newLabel
		setTypesList([...typesList])
	}
	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}
	useEffect(()=>{
		if(reasonId !== null){
			formik.setValues({
				reason: "",
				type: "expenses_outcome",
			})
		}
	}, [])
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="md"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span className="pr-5">{t("expense.modal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
			{isLoading || isFetching ? (
					<div className="circular-progress-box py-5">
						<CircularProgress size={35} />
					</div>
				) : (
				<form onSubmit={formik.handleSubmit} className="pt-2">
					<Grid
						container
						spacing={{ xs: 2, sm: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={12} xs={12}>
							<FormTextField
								delay={0.1}
								label={t("expense.modal.fields.comment")}
								fieldName="reason"
								formik={formik}
							/>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
								<div>
									<div className="font-medium text-lg">
										{t("lead.typeModal.list")}
									</div>
									<div>
										{typesList.map((item) => (
											<div className="flex flex-row" key={item.id}>
												<TextField
													color="formColor"
													variant="outlined"
													fullWidth
													id={`type-${item.id}`}
													name={`type-${item.id}`}
													value={item.reason}
													onChange={(event) =>
														handleChangeItemLabel(item.id, event.target.value)
													}
													InputProps={{
														readOnly: true
													}}
													autoComplete="off"
												/>
												<Button
													variant={
														!item.editable
															? "actionLarge"
															: "actionLargeOutlined"
													}
													color="warning"
													className="!ml-2 !mt-2"
													onClick={() => handleChangeEditable(item.id)}
												>
													<i className="bi bi-pencil-square" />
												</Button>
											</div>
										))}
									</div>
								</div>
							</Grid>
						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								delay={0.5}
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ReasonAddEdit
