import { Button, CircularProgress, Grid } from "@mui/material"
import PaymentFiltersComponent from "components/ui/filters/PaymentFiltersComponent"
import FromDateToDateFilter from "components/ui/filters/items/FromDateToDateFilter"
import PaymentTable from "components/ui/tables/PaymentTable"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNavigationByRole from "hooks/useNavigationByRole"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useSearchParams } from "react-router-dom"
import { paymentTypeSelectOptions } from "shared/selectOptionsList"
import * as XLSX from "xlsx"
import SearchInput from "components/SearchInput"
const Payment = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(false)
	const [paymentsDetails, setPaymentsDetails] = useState({})
	const axiosPrivate = useAxiosPrivate()
	const [searchParams, setSearchParams] = useSearchParams()
	const { navigateFn } = useNavigationByRole()
	const [expanded, setExpanded] = useState(false)
	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("payment.title")}
			</div>
		)
	}, [i18n.language])

	// const handleFromDate = (date) => {
	// 	if (moment(date, "YYYY-MM-DD").isValid()) {
	// 		setFromDate(date)
	// 		if (moment(endDate, "YYYY-MM-DD") < moment(date, "YYYY-MM-DD")) {
	// 			setEndDate(
	// 				moment(date, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")
	// 			)
	// 		}
	// 	} else {
	// 		setFromDate(moment().startOf("month").format("YYYY-MM-DD"))
	// 	}
	// }

	// const handleEndDate = (date) => {
	// 	if (moment(date, "YYYY-MM-DD").isValid()) {
	// 		setEndDate(date)
	// 		if (moment(date, "YYYY-MM-DD") < moment(fromDate, "YYYY-MM-DD")) {
	// 			setFromDate(
	// 				moment(date, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD")
	// 			)
	// 		}
	// 	} else {
	// 		setEndDate(moment().endOf("month").format("YYYY-MM-DD"))
	// 	}
	// }

	/* {
		"id": 28,
		"contract_id": "1",
		"user_id": "1",
		"date": "2023-11-12",
		"sum": "2000000.0",
		"type_id": "1",
		"isvalute": "0",
		"valute": "0",
		"contract": {
			"id": 1,
			"name": "13/29",
			"user_id": "1",
			"home_id": "16",
			"client_id": "8",
			"client_type": "1",
			"square": "75.170",
			"price": "5188240.0",
			"sum": "390000000.0",
			"left": "81600000.0",
			"discount": "0.0",
			"start_price": "70000000.0",
			"date": "2023-07-13",
			"comment": "-",
			"status": "1",
			"isrepaired": "0",
			"peniya": "15870000",
			"isvalute": "0",
			"valute": "0",
			"custom": {
				"id": 8,
				"name": "FERUZ",
				"surname": "ABRUYEV",
				"middlename": "BURIBAYEVICH",
				"client_type": "1",
				"phone": "998933109818",
				"phone2": "998933109818"
			}
		}
	} */

	const handleGetData = () => {
		setDataLoading(true)
		let entries = Object.fromEntries(searchParams)
		let values = { from: entries?.from, till: entries?.till, object: entries?.object, block: entries?.block, payment_type: entries?.payment_type }
		axiosPrivate
			.post("/dictionary/paymentexport", JSON.stringify(values), {
				headers: { "Content-Type": "application/json" }
			})
			.then((res) => {
				if (res && res.data && res.data?.data) {
					let rows1 = res.data?.data[0].map((item) => ({
						"To'lov raqami": item?.payment_number === null? item?.id :item?.payment_number,
						"Shartnoma raqami": item?.contract?.name,
						Mijoz: `${item?.contract?.custom?.surname} ${item?.contract?.custom?.name} ${item?.contract?.custom?.middlename}`,
						Sana: moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						"Mas'ul": "",
						"To'lov turi": t(
							paymentTypeSelectOptions.find(
								(paymentType) => paymentType.code === item?.type_id
							)?.label
						),
						"To'lov maqsadi": "Shartnoma",
						Summa: item?.sum,
						"Ayirboshlash summasi": "",
						Tranzaksiya: "Kiruvchi"
					}))
					let rows = res.data?.data[0].map((item) => [
						item?.payment_number === null? item?.id :item?.payment_number,
						item?.contract?.name,
						`${item?.contract?.custom?.surname} ${item?.contract?.custom?.name} ${item?.contract?.custom?.middlename}`,
						moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						// "",
						t(
							paymentTypeSelectOptions.find(
								(paymentType) => paymentType.code === item?.type_id
							)?.label
						),
						"Shartnoma",
						parseFloat(item?.sum),
						// "",
						"Kiruvchi"
					])
					rows.unshift([
						"To'lov raqami",
						"Shartnoma raqami",
						"Mijoz",
						"Sana",
						"To'lov turi",
						"To'lov maqsadi",
						"Summa",
						"Tranzaksiya"
					])

					let worksheet = XLSX.utils.aoa_to_sheet(rows)
					// let worksheet = XLSX.utils.json_to_sheet(rows1)

					// let maxWidthArr = Object.keys(rows[0]).map((item) => {
					// 	return {
					// 		wch: rows
					// 			.map((r) => r[item])
					// 			.reduce((acc, curr) => {
					// 				return Math.max(acc, curr.toString().length)
					// 			}, 15)
					// 	}
					// })
					// worksheet["!cols"] = maxWidthArr

					let maxWidthArr = Object.keys(rows1[0]).map((item) => {
						return {
							wch:
								rows1
									.map((r) => r[item])
									.reduce((acc, curr) => {
										return Math.max(acc, curr.toString().length)
									}, 15) + 2
						}
					})
					// console.log("maxWidthArr = ", maxWidthArr)
					worksheet["!cols"] = maxWidthArr
					// console.log("rows = ", rows)

					let fmt = "#,##0.00 [$UZS]"
					let range = { s: { r: 1, c: 6 }, e: { r: rows.length - 1, c: 6 } }
					for (let R = range.s.r; R <= range.e.r; ++R) {
						for (let C = range.s.c; C <= range.e.c; ++C) {
							let cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
							if (!cell || cell.t !== "n") continue
							cell.z = fmt
							console.log(cell);
						}
					}

					// XLSX.utils.sheet_add_aoa(worksheet, [["Test"]], {
					// 	origin: "F23"
					// })

					let workbook = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(workbook, worksheet, t("payment.title"))
					// worksheet.F23 = { t: "s", v: "Test" }
					XLSX.writeFile(
						workbook,
						`${t("payment.title")}_${moment(entries?.from, "YYYY-MM-DD").format(
							"DD-MM-YYYY"
						)}_${moment(entries?.till, "YYYY-MM-DD").format(
							"DD-MM-YYYY"
						)}.xlsx`,
						{
							type: "array",
							bookType: "xlsx"
						}
					)
				}
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => setDataLoading(false))
		// let data = [
		// 	{ Nomi: "Moran", Roli: "back" },
		// 	{ Nomi: "Alain", Roli: "front" },
		// 	{ Nomi: "Tony", Roli: "back" },
		// 	{ Nomi: "Mike", Roli: "back" },
		// 	{ Nomi: "Abo", Roli: "back" },
		// 	{ Nomi: "Toni", Roli: "back" }
		// ]

		// const ws = XLSX.utils.json_to_sheet(data)

		/* const ws = XLSX.utils.sheet_add_aoa(
			[],
			[
				["", "Nomi", "Roli"],
				["", "xx1", "yy1"],
				["", "xx2", "yy2"],
				["", "xx3", "yy3"],
				["", "xx4", "yy4"],
				["", "xx5", "yy5"],
				["", "xx6", "yy6"],
				["", "xx7", "yy7"]
			],
			{
				skipHeader: true,
				origin: -1
			}
		) */

		// ws["A1"].s = { fill: { fgColor: { rgb: "#111111" } } }
		// const wb = XLSX.utils.book_new()
		// XLSX.utils.book_append_sheet(wb, ws, t("payment.title"))

		// XLSX.writeFile(wb, `${t("payment.title")}.xlsx`)
	}

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-1 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14">
					<Button
							variant="filterOutlined"
							color="primary"
							startIcon={<i className="bi bi-filter" />}
							className="!mr-2"
							onClick={() => setExpanded((prev) => !prev)}
						>
							{t("common.button.filter")}
					</Button>
					<SearchInput inputKey="name" />
					<div className="ml-2 flex">
						<FromDateToDateFilter />
					</div>
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="success"
							className="!mx-2"
							onClick={() => navigateFn("/BASE/payment/add")}
						>
							<i className="bi bi-plus-circle" />
						</Button>
						<Button
							variant="action"
							color="primary"
							className="!mr-2"
							onClick={() => handleGetData()}
							disabled={dataLoading}
						>
							{dataLoading ? (
								<div className="flex items-center justify-center">
									<CircularProgress size={17.5} color="inherit" />
								</div>
							) : (
								<i className="bi bi-download" />
							)}
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
				{expanded && (
					<div className="my-shadow-2 rounded-lg px-4 w-full mt-2">
						<PaymentFiltersComponent status />
					</div>
				)}
			</div>

			<div className="mb-5">
				<Grid
					container
					spacing={2}
					columns={{ xs: 14, sm: 14, md: 14, lg: 14, xl: 14 }}
				>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("payment.totalReceipts")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="inherit"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.pay}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]" 
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-green-700 flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.cash")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="success"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.naqd}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color-light flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.card")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="secondary"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.kartaUzcard}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color-light flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.card2")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="secondary"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.kartaHumo}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-blue-500 flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.p2p")}
								</span>
								{refetch ? (
									<CircularProgress size={20} color="info" className="mt-1" />
								) : (
									<NumericFormat
										value={paymentsDetails?.p2p}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-orange-400 flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.bank")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="warning"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.bank}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-red-500 flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("common.typeList.paymentType.act")}
								</span>
								{refetch ? (
									<CircularProgress size={20} color="error" className="mt-1" />
								) : (
									<NumericFormat
										value={paymentsDetails?.akt}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			<div className="component-table-wrapper">
				<PaymentTable
					emitRefetch={{ refetch, setRefetch }}
					emitTableColumns={{ open, setOpen }}
					actionGetCheque={true}
					actionContractView={true}
					setPaymentsDetails={setPaymentsDetails}
				/>
			</div>
		</div>
	)
}

export default Payment
