import {
	CircularProgress,
	FormControl,
	MenuItem,
	Select
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

const BlockSelectBox = ({
	value = "",
	setValue = () => {},
	size = "medium",
	objectId = ""
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["blocksSelect", objectId],
		queryFn: async function () {
			if(objectId && objectId !== 'ALL') {
				const response = await axiosPrivate.get(`admin/block/index/${objectId}`)
			return response.data.data
			}
			return []
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<FormControl color="formColor" fullWidth>
			<Select
				labelId="blocks-filter-label"
				label=""
				id="blocks-filter-multiple-chip"
				value={value}
				onChange={(event) => setValue(event?.target?.value)}
				color="formColor"
				variant="outlined"
				size={size}
				MenuProps={{
					id: "objectId-select-menu",
					PaperProps: {
						style: {
							maxHeight: 300
						}
					},
					disableScrollLock: true
				}}
			>
				<MenuItem value="ALL" className={size === "small" ? "small-size" : ""}>
					{t("common.fields.allBlocks")}
				</MenuItem>
				{isLoading || isFetching ? (
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				) : data && data.length > 0 && (
					data.map((item, index) => (
						<MenuItem
							value={item.id}
							key={`object-${index + 1}`}
							className={size === "small" ? "small-size" : ""}
						>
							{item.name}
						</MenuItem>
					))
				)}
			</Select>
		</FormControl>
	)
}
export default BlockSelectBox