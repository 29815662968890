import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormMultilineTextField from "components/ui/form/FormMultilineTextField"
import FormSelectChangeFnField from "components/ui/form/FormSelectChangeFnField"
import FormSelectField from "components/ui/form/FormSelectField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import useAuth from "hooks/useAuth"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import { motion } from "framer-motion"
import { fadeUp } from "utils/motion"
import ReasonAddEdit from "./ReasonAddEdit"
const validationSchema = yup.object({
	sum: yup.number().required("expense.modal.validation.sum"),
	payment_type: yup.number().required("expense.modal.validation.paymentType"),
	comment: yup.string("expense.modal.validation.comment").notRequired(".").no,
	object_id: yup.string().notRequired(),
	reason_id: yup.string().required("Chiqim turi"),
	block_id: yup.string().notRequired()
})

const ExpenseAddModal = (props) => {
	const { open, setOpen, setRefetch } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [{ user }] = useAuth()
	const [openTypeModal, setOpenTypeModal] = useState(false)
	const [typeOptionsRefetch, setTypeOptionsRefetch] = useState(false)

	const formik = useFormik({
		initialValues: {
			sum: "",
			payment_type: "",
			comment: "",
			object_id: "",
			block_id: "",
			reason_id: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				let newValues = { ...values }
				if (newValues.object_id) {
					delete newValues.object_id
				}
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/accounter/rasxod/store",
					JSON.stringify(newValues),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data && response.data.status) {
					sendNotification({
						msg: t("expense.modal.alerts.success"),
						variant: "success"
					})
					setIsSubmitting(false)
					handleClose()
					setRefetch(true)
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const handleObjectChange = (value) => {
		formik.setFieldValue("object_id", value, true)
		formik.setFieldValue("block_id", "", true)
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="md"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span className="pr-5">{t("expense.modal.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="pt-2">
					<Grid
						container
						spacing={{ xs: 2, sm: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
							<FormCurrencyField
								delay={0.1}
								label={t("expense.modal.fields.sum")}
								fieldName="sum"
								formik={formik}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormAutocompleteField
								delay={0.2}
								label={t("expense.modal.fields.paymentType")}
								fieldName="payment_type"
								formik={formik}
								path={"/dictionary/types"}
							/>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
							<FormSelectChangeFnField
								delay={0.3}
								label={t("common.fields.objectName")}
								fieldName="object_id"
								formik={formik}
								path="/dictionary/objects2"
								changeFn={handleObjectChange}
							/>
						</Grid>

						{formik.values.object_id && (
							<Grid item={true} sm={6} xs={12}>
								<FormSelectField
									delay={0}
									label={t("common.fields.blockName")}
									fieldName="block_id"
									formik={formik}
									path={`admin/block/index/${formik.values.object_id}`}
									pathChangeable={true}
								/>
							</Grid>
						)}
						<Grid item={true} sm={6} xs={12}>
						<div className="flex flex-row">
							<FormAutocompleteField
								delay={0.3}
								label={t("expense.modal.fields.comment")}
								fieldName="reason_id"
								formik={formik}
								path={"/reasons"}
								name={"reason"}
								emitRefetch={{
									refetch: typeOptionsRefetch,
									setRefetch: setTypeOptionsRefetch
								}}
							/>
							{user?.user?.role === ROLE_TYPE_LIST.ADMIN.code && (
									<Button
										type="button"
										component={motion.button}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										variant="actionLarge"
										color="primary"
										className="!ml-2 !mt-2"
										onClick={() => setOpenTypeModal(true)}
									>
										<i className="bi bi-plus-lg" />
									</Button>
								)}
						</div>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<FormMultilineTextField
								delay={0.4}
								label={t("expense.modal.fields.typeExpenses")}
								fieldName="comment"
								formik={formik}
								rows={3}
								required={false}
							/>
						
						</Grid>
						

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								delay={0.5}
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			{openTypeModal&&
			<ReasonAddEdit 
			open={openTypeModal}
			setOpen={setOpenTypeModal}
			setRefetch={setTypeOptionsRefetch}
			reasonId={[]}
			/>}
		</Dialog>
	)
}

export default ExpenseAddModal
