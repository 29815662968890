import { ButtonBase, Grid } from "@mui/material"
import React, { Fragment, useEffect } from "react"
import { useTranslation } from "react-i18next";

const Shaxmatka2Row = ({
	homesData,
	blockIndex,
	floorNumber,
	size,
	toggleSelectionItem = () => {}
}) => {
	const {t} = useTranslation()
	const classes = Array.from({ length: 50 }, (v, i) => i);
	useEffect(()=>{
		const sheetColumns = document.querySelectorAll(".sheet-column")
		sheetColumns.forEach((sheetCol)=>{
			const divs = sheetCol.querySelectorAll(".sheet-home-row")
			divs.forEach(div=>{
				classes.forEach(className => {
					const elements = div.querySelectorAll(`.padez_id_${className}`);
					if (elements.length > 0) {
						elements[elements.length - 1].classList.add(`last-of-class-${className}`);
					}
					
				});
			})
			classes.forEach(className => {
				const element = divs[0].querySelectorAll(`.padez_id_${className}`);
				if (element.length > 0) {
					element[0].classList.add(`first-of-class`);
					element[0].setAttribute("padyezd", t("padyezd.padez",{indexPadez: className}))
				}
				
			});
		})
	},[])
	return (
		<Fragment>
			<Grid
				container
				rowSpacing={0.5}
				columns={{
					xs: parseInt(size),
					sm: parseInt(size)
				}}
				className="sheet-home-row"
			>
				<Grid
					item={true}
					sm={1}
					xs={1}
					className="sheet-home-cell !-ml-2.5 !mr-0"
				>
					<ButtonBase
						className="sheet-home-row-floor h-full w-8 text-sm font-medium !cursor-default"
						disableRipple
					>
						{floorNumber}
					</ButtonBase>
				</Grid>
				{homesData.filter((home) => home.stage === floorNumber).length > 0
					? homesData
							.filter((home) => home.stage === floorNumber).sort((x, y) =>x.stage>=1 && +x.number - +y.number)
							.map((item) => (
								<Grid
									item={true}
									sm={1}
									xs={1}
									key={`block-${blockIndex}-home-${item.id}`}
									id={`block-${blockIndex}-home-${item.id}`}
									className={`sheet-home-cell floor-${floorNumber} padez_id_${item.padez}`}
								>
									<ButtonBase
										className={`block-${blockIndex}-home home-item status-${
											item.status
										}${item.disabled ? " is-disabled" : ""}`}
										id={`home-${item.id}`}
										onClick={() => toggleSelectionItem(item.id, blockIndex)}
									>
										{!(item.stage && parseInt(item.stage) < 0) ? (
											<div className="text-[12px]">{item.number}</div>
										) : (
											<div>P</div>
										)}
									</ButtonBase>
								</Grid>
							))
					: [1].map((item) => (
							<Grid
								item={true}
								sm={1}
								xs={1}
								key={`block-${blockIndex}-home-empty-${item}`}
								id={`block-${blockIndex}-home-empty-${item}`}
								className={`sheet-home-cell sheet-home-empty-cell padez_id_${item.padez} floor-${floorNumber}`}
							>
								<ButtonBase className="home-item is-empty">
									<div className="home-item-data"></div>
								</ButtonBase>
							</Grid>
					  ))}
			</Grid>
		</Fragment>
	)
}

export default Shaxmatka2Row
