import { Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormPasswordField from "components/ui/form/FormPasswordField"
import FormTextField from "components/ui/form/FormTextField"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useFormik } from "formik"
import * as yup from "yup"
import { useTranslation } from 'react-i18next'
import useLocalStorage from "hooks/useLocalStorage"
const validationSchema = yup.object({
	login: yup
		.string()
		.required("settings.message.serverDetailsAddEditModal.validation.login"),
	password: yup
		.string()
		.required("settings.message.serverDetailsAddEditModal.validation.password"),
    nickname: yup
		.string()
		.required(
			"settings.message.serverDetailsAddEditModal.validation.originator"
		)
})
const MessageSetting = (props) => {
    const {setOpenTemplateModal,setItemId} = props
    const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
    const [mesSet, setMesSet] = useLocalStorage("settingMessage", null)
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
    const formik = useFormik({
		initialValues: {
			login: "",
			password: "",
            nickname: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				let newValues = { ...values }
				if (newValues.object_id) {
					delete newValues.object_id
				}
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/play_mobile/save",
					JSON.stringify(newValues),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data) {
					sendNotification({
						msg: t("settings.message.serverDetailsAddEditModal.alerts.success"),
						variant: "success"
					})
					setIsSubmitting(false)
                    setMesSet(response?.data?.data)
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})
    useEffect(()=>{
        formik.setValues({
            login: mesSet?.login,
			password: mesSet?.password,
            nickname: mesSet?.nickname
        })
    }, [])
  return (
    <div className="rounded-[7px]" style={{display: "flex", justifyContent: "center"}}>
    <Grid
        container
        spacing={{ xs: 2, sm: 3, lg: 2 }}
        rowSpacing={3}
        columns={{ xs: 12, sm: 12, lg: 12 }}
        >
        <Fragment>
        <Grid item={true} lg={12} sm={12} xs={12}>
                            <div className="rounded-[7px] p-4 bg-[#fff]"  style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>Habarnoma tizimi</div>
        </Grid>
        
        <Grid item={true} lg={5} sm={12} xs={12}>
            <div className="rounded-[7px] px-4 py-9 bg-[#fff]"  style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
            <form onSubmit={formik.handleSubmit} className="pt-2 ">
            <Grid item={true} sm={12} xs={12} className='w-full flex justify-center items-center'>
                <img src={require("assets/images/logo/playmobile.png")} alt="" />
            </Grid>
            <Grid item={true} sm={12} xs={12}>
                <FormTextField
                    delay={0.1}
                    label={t("common.fields.login")}
                    fieldName="login"
                    formik={formik}
                />
            </Grid>

            <Grid item={true}  sm={12} xs={12}>
                <FormPasswordField
                    delay={0.2}
                    label={t("common.fields.password")}
                    fieldName="password"
                    formik={formik}
                />
            </Grid>
            <Grid item={true} sm={12} xs={12}>
                <FormTextField
                    delay={0.3}
                    label={t("Originator")}
                    fieldName="nickname"
                    formik={formik}
                />
            </Grid>
            <Grid item={true} sm={12} xs={12}>
                <FormActionButtons
                    delay={0.4}
                    isSubmitting={isSubmitting}
                    formType="simple"
                    reset={formik.resetForm}
                    onlySave={true}
                />
            </Grid>
            </form>
                            </div>
        </Grid>
        {/* <Grid item={true} lg={7} sm={12} xs={12}>
        <div className="rounded-[7px] p-4 bg-[#fff] h-full"  style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
            <Grid item={true} sm={12} xs={12}>
               <div className="header_originator flex justify-between items-center">
                    <div className="header_text text-[18px] font-medium">Originators</div>
               </div>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
               <div className="header_originator rounded-[7px] px-3 py-2 mt-5 border flex justify-between items-center">
                    <div className="header_text text-[20px] font-medium">Saleuz</div>
                    <div>
                    <EditModalActionButton
                        setItemId={setItemId}
                        setOpen={setOpenTemplateModal}
                        id={1}
                    />
                    <DeleteActionButton
                        // link={actionDelete.link+'/'+row.id}
                        // data={{ [actionDelete.key]: row.id }}
                        // refetch={refetch}
                        
															/>
                    </div>
               </div>
            </Grid>
        </div>
        </Grid> */}
        </Fragment>
        
    </Grid>
   
    </div>
  )
}

export default MessageSetting