import { CurrencyContext } from "context/index"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import useAxiosPrivate from "hooks/useAxiosPrivate"

const CurrencyProvider = ({ children }) => {
	const [hasError, setHasError] = useState(false)
	const axiosPrivate = useAxiosPrivate()

	const { data: currencyData, refetch: refreshCurrency } = useQuery({
		queryKey: "dailyCurrency",
		queryFn: async function () {
			const response = await axiosPrivate.get("/dictionary/valute")
			return response?.data?.data || {}
		},
		enabled: !hasError, //false
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	// useEffect(() => {
	// 	refreshCurrency()
	// }, [])

	return (
		<CurrencyContext.Provider value={{ currencyData, refreshCurrency }}>
			{children}
		</CurrencyContext.Provider>
	)
}

export default CurrencyProvider
