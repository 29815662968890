import React, { useEffect } from 'react';

const PlanoplanWidget = () => {
    // Create script element and add it to the document
    const script = document.createElement('script');
    script.src = 'https://widget.planoplan.com/etc/multiwidget/release/static/js/main.js';
    script.async = true;
    script.onload = () => {
      // Initialize Planoplan widget after script is loaded
      window.Planoplan.init({
        width: '100%',
        height: "300px",
        primaryColor: '#81AEE3',
        secondaryColor: '#F4F7F7',
        textColor: '#4A4A4A',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Open Sans, sans-serif',
        activeTab: 'none',
        activeFloor: '0',
        activeDesign: '873032',
        uid: '94b725e8807b98d8bf38afa43e8b5eea726a9ffc06a3d06754530d658a010372',
        el: 'pop-widget',
        lang: 'ru',
        initialCamera: [
          {
            design: 873032,
            floor: 1,
            camera: 6
          }
        ]
      });
    };
    document.body.appendChild(script);

    // Cleanup script when component is unmounted
  return <div id="pop-widget"></div>;
};

export default PlanoplanWidget;
