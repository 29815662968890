import { Button, FormControl, FormHelperText } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { fadeUp } from "utils/motion";
import BaseTooltip from "../tooltips/BaseTooltip";
import useNotification from "hooks/useNotification";
import { useTranslation } from "react-i18next";
import formLocalizedHelperText from "utils/formLocalizedHelperText";
import axios from "axios";
import { string } from "yup";

const FormFileUploadField = ({
	delay = 0,
	formik,
	fieldName,
	label = "",
	btnLabel = "Yuklash",
	accept = "image/jpeg, image/png",
	fileSize = 110,
	disabled = false,
	setOpen,
	seturl
}) => {
	const { t } = useTranslation();
	const sendNotification = useNotification();
	const [fileSizeImg, setFileSizeImg] = useState(0);
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && Math.floor(file.size / 1024) <= fileSize * 1024) {
			formik.setFieldValue(fieldName, file, true);
		} else {
			sendNotification({
				msg: t("common.alerts.warning.fileSize", { value: fileSizeImg }),
				variant: "warning"
			});
		}
	};
	const handleFileRemove = () => {
		formik.setFieldValue(fieldName, null, true);
	};
	return (
		<FormControl
			component={motion.div}
			variants={fadeUp(30, "tween", delay, 0.5)}
			initial="hidden"
			animate="show"
			viewport={{ once: true, amount: 0.25 }}
			color="formColor"
			fullWidth
		>
			<div className="flex items-center p-2 border border-dashed rounded-lg">
				{formik.values[fieldName]? (
					<div className="flex items-center w-full space-x-4">
						<img
							src={formik.values[fieldName] && typeof formik.values[fieldName] == "string" ? formik.values[fieldName] :URL.createObjectURL(formik.values[fieldName])}
							alt={formik.values[fieldName].name}
							className="w-12 h-12 object-cover rounded cursor-pointer"
							onClick={(e)=>{
								setOpen(true)	
								seturl(e.target.currentSrc)
							}}
						/>
						<div className="flex-1">
							<div className="text-sm font-medium w-16 text-nowrap text-ellipsis overflow-hidden">{formik.values[fieldName].name}</div>
							<div className="text-xs text-gray-500">{(formik.values[fieldName].size / 1024).toFixed(2)} KB</div>
						</div>
						<Button
							variant="text"
							color="secondary"
							onClick={handleFileRemove}
							className="min-w-0 p-2"
						>
							<i className="bi bi-trash" />
						</Button>
					</div>
				) : (
					<>
						<Button
							variant="contained"
							component="label"
							className={`upload-btn${formik.touched[fieldName] && Boolean(formik.errors[fieldName]) ? "field-required-error" : ""}`}
							disabled={disabled}

						>
							<i className="bi bi-upload mr-2" /> {btnLabel}
							<input
								hidden
								id={fieldName}
								name={fieldName}
								label={label}
								type="file"
								accept={accept}
								onChange={handleFileChange}
							/>
						</Button>
						<span className="text-xs text-gray-500 ml-2">JPG, PNG</span>
					</>
				)}
			</div>
			<FormHelperText
				children={
					<span>
						{formik.touched[fieldName] && formLocalizedHelperText(formik.errors[fieldName])}
					</span>
				}
				error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
			/>
		</FormControl>
	);
}

export default FormFileUploadField;
