import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Fragment } from "react"
import { useSearchParams } from "react-router-dom"

const FromDateToDateFilter = () => {
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const [fromDate, setFromDate] = useState(
		moment().startOf("month").format("YYYY-MM-DD")
	)
	const [endDate, setEndDate] = useState(
		moment().endOf("month").format("YYYY-MM-DD")
	)

	const handleFromDate = (date) => {
		if (moment(date, "YYYY-MM-DD").isValid()) {
			setFromDate(date)
			searchParams.set("from", date)
			if (moment(endDate, "YYYY-MM-DD") < moment(date, "YYYY-MM-DD")) {
				setEndDate(
					moment(date, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")
				)
				searchParams.set(
					"till",
					moment(date, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD")
				)
			} else {
				searchParams.set("till", endDate)
			}
			setSearchParams(searchParams, { replace: true })
		} else {
			setFromDate(moment().startOf("month").format("YYYY-MM-DD"))
		}
	}

	const handleEndDate = (date) => {
		if (moment(date, "YYYY-MM-DD").isValid()) {
			setEndDate(date)
			searchParams.set("till", date)
			if (moment(date, "YYYY-MM-DD") < moment(fromDate, "YYYY-MM-DD")) {
				setFromDate(
					moment(date, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD")
				)
				searchParams.set(
					"from",
					moment(date, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD")
				)
			} else {
				searchParams.set("from", fromDate)
			}
			setSearchParams(searchParams, { replace: true })
		} else {
			setEndDate(moment().endOf("month").format("YYYY-MM-DD"))
		}
	}

	return (
		<Fragment>
			<div>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						id="from-date-date-picker"
						openTo="day"
						value={fromDate}
						onChange={(newValue) => {
							handleFromDate(moment(newValue).format("YYYY-MM-DD"))
						}}
						views={["year", "month", "day"]}
						inputFormat="DD/MM/yyyy"
						InputProps={{
							size: "small"
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								color="formColor"
								variant="outlined"
								fullWidth
								id="fromDate-date-picker-text-field"
								name="from-date"
								label={t("common.fields.fromDate")}
								autoComplete="off"
							/>
						)}
					/>
				</LocalizationProvider>
			</div>
			<div className="mx-1.5">
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DatePicker
						id="end-date-date-picker"
						openTo="day"
						value={endDate}
						onChange={(newValue) => {
							handleEndDate(moment(newValue).format("YYYY-MM-DD"))
						}}
						views={["year", "month", "day"]}
						inputFormat="DD/MM/yyyy"
						InputProps={{
							size: "small"
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								color="formColor"
								variant="outlined"
								fullWidth
								id="fromDate-date-picker-text-field"
								name="end-date"
								label={t("common.fields.endDate")}
								autoComplete="off"
							/>
						)}
					/>
				</LocalizationProvider>
			</div>
		</Fragment>
	)
}

export default FromDateToDateFilter
