import {
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import moment from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

const ContractMessageSettingsModal = (props) => {
	const { open, setOpen } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const [messages, setMessages] = useState([])
	const sendNotification = useNotification()

	const handleClose = () => {
		setOpen(false)
	}

	const { isLoading, isFetching, isError, error } = useQuery({
		queryKey: "contractPaymentList",
		queryFn: async function () {
			// const response = await axiosPrivate.get("")
			// return response.data.data
			return []
		},
		onSuccess: (data) => {
			setMessages([...data])
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	const handleChangeAutoMessageSend = (value, messageId) => {
		let foundMessage = messages.find((item) => item.id === messageId)
		if (foundMessage) {
			foundMessage.isActive = value
		}
		setMessages([...messages])
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="payment-dialog-title">
				<span className="pr-5">Shartnoma xabar sozlamalari</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="mt-2">
					<TableContainer className="flex-auto h-full">
						<Table
							stickyHeader
							sx={{ minWidth: 750, height: "max-content" }}
							aria-labelledby="tableTitle"
						>
							<TableHead>
								<TableRow>
									<TableCell>{t("common.table.name")}</TableCell>
									<TableCell align="right">
										{t("common.table.status")}
									</TableCell>
								</TableRow>
							</TableHead>
							{isLoading || isFetching ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={2}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								</TableBody>
							) : isError ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={2}>
											<div className="flex flex-col items-center">
												{error?.response?.data?.message && (
													<span className="text-red-600 font-medium">
														{error?.response?.data?.message}
													</span>
												)}
												{error?.response?.data?.details &&
													error?.response?.data?.details[0]?.message && (
														<div>
															<span className="text-red-600 font-medium">
																{t("common.errors.message")}
															</span>
															<span>
																{error?.response?.data?.details[0]?.message}
															</span>
														</div>
													)}
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							) : messages && messages.length > 0 ? (
								<TableBody className="overflow-hidden">
									{messages.map((row, rowIndex) => {
										return (
											<TableRow hover tabIndex={-1} key={"row-" + rowIndex}>
												<TableCell>{row?.name}</TableCell>
												<TableCell align="right">
													<Switch
														color="secondary"
														checked={row?.isActive}
														onChange={(event) =>
															handleChangeAutoMessageSend(
																event?.target?.checked,
																row?.id
															)
														}
													/>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell colSpan={2}>
											<span className="no-data-found-wrapper">
												<i className="bi bi-exclamation-octagon icon-xl" />{" "}
												{t("common.global.noDataFound")}
											</span>
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<div className="mt-4 text-center">
						<Button variant="contained" color="success">
							{t("common.button.save")}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ContractMessageSettingsModal
