import { ButtonBase, Checkbox, CircularProgress, FormLabel, Grid, selectClasses } from "@mui/material"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useCurrency from "hooks/useCurrency"
import useTopPanel from "hooks/useTopPanel"
import React, { Fragment, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { useParams } from "react-router-dom"
import * as yup from "yup"
import FormSimpleSelectField from "components/ui/form/FormSimpleSelectField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import ImagePreviewDialog from "components/ui/dialogs/ImagePreviewDialog"
import {
	currencyTypeSelectOptions,
	repairTypeSelectOptions,
	residentTypeSelectOptions,
	homeAllTypeSelectOptions
} from "shared/selectOptionsList"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import FormNumberField from "components/ui/form/FormNumberField"
import FormRadioGroupField from "components/ui/form/FormRadioGroupField"
// import FormLayoutField from "components/ui/form/FormLayoutField"
import FormTextField from "components/ui/form/FormTextField"
import FormFileUploadField from "components/ui/form/FormFileUploadField"
import FormActionButtons from "components/ui/form/FormActionButtons"
// import HOME_TYPE from "shared/homeTypeList"
import { NumericFormat } from "react-number-format"
import REPAIR_TYPE from "shared/repairTypeList"
import { formatMultiplies } from "utils/formatOddDecimal"
import BackButton from "components/ui/BackButton"
// import SimpleToggleButtonGroup from "components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup"
// import FormSimpleMultipleSelectField from "components/ui/form/FormSimpleMultipleSelectField"
import useNotification from "hooks/useNotification"

const validationSchema = yup.object({
	number: yup.string(),
	square: yup.number(),
	isvalute: yup.string().optional(),
	homeStatus: yup.string().optional(),
	uid: yup.string(),
	link: yup.mixed().notRequired(),
	Dimage: yup.mixed().notRequired(),
	addressimage: yup.mixed().notRequired(),
	repaired: yup.number(),
	norepaired: yup.number(),
	isrepaired: yup.string().notRequired()
})

const BlockEdit = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { currencyData } = useCurrency()
	const [selectedHomes, setSelectedHomes] = useState([])
	// const [getArrayStage, setGetArrayStage] = useState([])
	// const [homeSquareSelectOptions, setHomeSquareSelectOptions] = useState([])
	// const [homeStageSelectOptions, setHomeStageSelectOptions] = useState([])
	// const [selectionType, setSelectionType] = useState("SQUARE_AND_STAGE")
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [urlImage, setUrlImage] = useState("")
	const sendNotification = useNotification()
	const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] = useState(false)
	const parseToFormData = (values) => {
		let formData = new FormData()
		for (let key in values) {
			if (values[key]) {
				formData.append(key, values[key])
			}
		}
		return formData
	}
	
	const { setComponent } = useTopPanel()

	// const homesSelectionOptions = [
	// 	{
	// 		code: "SQUARE_AND_STAGE",
	// 		label: "block.edit.selectionOptions.squareAndStage"
	// 	},
	// 	{
	// 		code: "ONLY_STAGE",
	// 		label: "block.edit.selectionOptions.stage"
	// 	}
	// ]
	const validationValuesBlock = {
		number: "",
		square: "",
		isvalute: "0",
		homeStatus: "",
		rooms: "",
		start: "",
		repaired: "",
		islive: null,
		link: null,
		Dimage: null,
		addressimage: null,
		uid: "",
		norepaired: "",
		isrepaired: null
	}
	const formik = useFormik({
		initialValues: validationValuesBlock,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = {
				number: values.number,
				isvalute: values.isvalute,
				homeStatus: values.homeStatus,
				repaired: values.repaired,
				norepaired: values.norepaired,
				link: values.link,
				rooms: values.rooms,
				start: values.start,
				islive: values.islive,
				square: values.square,
				Dimage: values.Dimage,
				addressimage: values.addressimage,
				uid: values.uid,
				isrepaired: values.isrepaired,
				_method: "put"
			}
			try {
				if(!selectedHomes.length > 0) {
				setIsSubmitting(false)
				sendNotification({
					msg: "biror uyni tanlang",
					variant: "error"
				})
				}
				else{
				let multipleResponse = await Promise.all(
					selectedHomes.map((homeId) =>
						axiosPrivate.post(
							`/admin/home/update/${homeId}`,
							parseToFormData(newValues),
							{
								headers: { "Content-Type": "multipart/form-data" }
							}
						)
					)
				)
				handleMultipleResponse(multipleResponse)
				setIsSubmitting(true)
			}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})
	// const formikStages = useFormik({
	// 	initialValues: {
	// 		stages: [],
	// 		isvalute: "0",
	// 		repaired: "",
	// 		norepaired: "",
	// 		image: null,
	// 		isrepaired: null
	// 	},
	// 	validationSchema: validationStagesSchema,
	// 	onSubmit: async (values) => {
	// 		let newValues = {
	// 			image: values.image,
	// 			isvalute: values.isvalute,
	// 			repaired: values.repaired,
	// 			norepaired: values.norepaired,
	// 			isrepaired: values.isrepaired,
	// 			_method: "put"
	// 		}
	// 		setIsSubmitting(true)
	// 		try {
	// 			let multipleResponse = await Promise.all(
	// 				selectedHomes.map((homeId) =>
	// 					axiosPrivate.post(
	// 						`/admin/home/newprice/${homeId}`,
	// 						parseToFormData(newValues),
	// 						{
	// 							headers: { "Content-Type": "application/json" }
	// 						}
	// 					)
	// 				)
	// 			)
	// 			handleMultipleResponse(multipleResponse)
	// 		} catch (error) {
	// 			sendNotification({
	// 				msg: error?.response?.data?.message || error?.message,
	// 				variant: "error"
	// 			})
	// 			setIsSubmitting(false)
	// 		}
	// 	}
	// })
	const [homeQuery, blockQuery] = useQueries([
		{
			queryKey: "homesBlock" + id,
			queryFn: async function () {
				const response = await axiosPrivate.get(`admin/home/index/${id}`)
				return response.data.data
			},
			onSuccess: (data) => {
				// setAllHomeSquare([...data])
			},
			enabled: !hasError && !!id,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "blockSingle" + id,
			queryFn: async function () {
				const response = await axiosPrivate.get(`/admin/block/edit/${id}`)
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])
	
	// const setAllHomeSquare = (homesData) => {
	// 	setHomeSquareSelectOptions(
	// 		Array.from(new Set(homesData.map((item) => item.square)))
	// 			.sort()
	// 			.map((item) => ({
	// 				value: item,
	// 				label: item.toString()
	// 			}))
	// 	)
	// }

	const handleMultipleResponse = (multipleResponse) => {
		let result = multipleResponse.reduce((acc, curr) => {
			return acc && curr && curr.data && curr.data.status
		}, true)
		if (result) {
			sendNotification({
				msg: t("settings.message.serverDetailsAddEditModal.alerts.update"),
				variant: "success"
			})
			formik.resetForm()
			resetDisabling()
			setSelectedHomes([])
			homeQuery.refetch()

		}
		blockQuery.refetch()

		setIsSubmitting(false)
	}

	const resetDisabling = () => {
		Array.from(document.getElementsByClassName("home-item")).forEach((item) =>
			item.classList.remove("is-disabled")
		)
	}

	const handleCurrencyChange = (value) => {
		formik.setFieldValue("isvalute", value, true)
		if (currencyData && currencyData.sum) {
			if (value === "1") {
				formik.setFieldValue(
					"repaired",
					parseFloat(
						parseFloat(
							(formik.values.repaired || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat(
						parseFloat(
							(formik.values.norepaired || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"start",
					parseFloat(
						parseFloat((formik.values.start || "0") / currencyData.sum).toFixed(
							1
						)
					),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"repaired",
					parseFloat((formik.values.repaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat((formik.values.norepaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"start",
					parseFloat((formik.values.start || "0") * currencyData.sum),
					true
				)
			}
		}
	}

	// const getRoomsByStatus = (statusCode) => {
	// 	return homeQuery?.data.length > 0
	// 		? homeQuery?.data.filter((item) => item.status === statusCode).length
	// 		: 0
	// }

	const getFullFloorsNumberByBlock = () => {
		let a = Array.from(new Set(homeQuery?.data.map((item) => item.stage)))
		if (a.includes("-1")) {
			a.push("0")
		}
		a.sort((x, y) => x - y)
		return a.reverse()
	}
	const setMultipleHomeValuesToForm = (homeId, blockIndex) => {
		// console.log("blocksQuery.data[blockIndex] = ", blocksQuery.data[blockIndex])
		let home = homeQuery?.data?.filter(
			(item) => item.id === homeId
		)[0]
		let newValues = {
			number: selectedHomes.length == 1 ? home.number || "" : "",
			rooms: selectedHomes.length == 1 ? home.rooms || "" : "",
			square: selectedHomes.length == 1 ? home.square || "" : "",
			isvalute:selectedHomes.length == 1 ?  home.isvalute || "0" : "",
			homeStatus:selectedHomes.length == 1 ?  home.status || "0" : "",
			islive:selectedHomes.length == 1 ?  home.islive || "" : "",
			repaired: selectedHomes.length == 1 ? home.repaired || "" : "",
			norepaired: selectedHomes.length == 1 ? home.norepaired || "" : "",
			isrepaired: selectedHomes.length == 1 ? home.isrepaired || "" : "",
			Dimage:selectedHomes.length == 1 ? home.plan && home.plan.Dimage ? process.env.REACT_APP_BACKEND_URL+"/"+home.plan.Dimage : "" : "",
			addressimage:selectedHomes.length == 1 ? home.plan && home.plan.addressimage ? process.env.REACT_APP_BACKEND_URL+"/"+home.plan.addressimage : "" : "",
			link:selectedHomes.length == 1 ? home.plan && home.plan.link ? process.env.REACT_APP_BACKEND_URL+"/"+home.plan.link : "" : "",
			start:selectedHomes.length == 1 ? home.start || "" : ""
		}
		formik.setValues(newValues)
	}
	// const handleHomeSquareChange = (homeSquare,checked) => {
	// 	console.log(homeSquare);
	// 	formik.setFieldValue("square", homeSquare, true)
	// 	formik.setFieldValue("stage", "", true)
	// 	const squareArr = []
	// 	if(checked){
	// 		squareArr.push(homeSquare)
	// 	}
	// 	else{
	// 		squareArr.filter(prev=> prev !== homeSquare)
	// 	}
		
	// 	setSelectedHomes([])
	// 	let selectedHomesId = []
	// 	setHomeStageSelectOptions(
	// 		Array.from(
	// 			new Set(
	// 				homeQuery?.data
	// 					.filter((item) => item.square === homeSquare)
	// 					.map((item) => item.stage)
	// 			)
	// 		)
	// 			.sort((a, b) => a - b)
	// 			.map((item) => ({
	// 				value: item,
	// 				label: item.toString()
	// 			}))
	// 	)

	// 	homeQuery?.data.forEach((item) => {
	// 		if(squareArr.includes(item.square) &&
	// 		item.status !== HOME_TYPE.ORDERED.code &&
	// 		item.status !== HOME_TYPE.TIME.code)
	// 			{
	// 				selectedHomesId.push(item?.id)
	// 				document
	// 					.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 					.classList.add("item-selected")
	// 			}
	// 			else{ document
	// 					.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 					.classList.remove("item-selected")
	// 			}
	// 	})
	// 	setSelectedHomes(selectedHomesId)
	// }

	// const handleHomeStageChange = (homeStage) => {
	// 	formik.setFieldValue("stage", homeStage, true)
	// 	homeQuery?.data.forEach((item) => {
	// 		if (
	// 			(item.square === formik.values.square ||
	// 				selectionType === "ONLY_STAGE") &&
	// 			item.stage === homeStage &&
	// 			item.status !== HOME_TYPE.ORDERED.code &&
	// 			item.status !== HOME_TYPE.TIME.code
	// 		) {
	// 			setSelectedHomes((prev) => [...prev, item.id])
	// 			document
	// 				.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 				.classList.remove("is-disabled")
	// 		} else {
	// 			document
	// 				.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 				.classList.add("is-disabled")
	// 		}
	// 	})
	// }
	// const handleHomeMultipleStageChange = (stages,checked) => {
	// 	const stagesArr = []	
	// 	if(checked){
	// 		stagesArr.push(stages)
	// 	}
	// 	else{
	// 		stagesArr.filter(prev=> prev !== stages)
	// 	}
	// 	formikStages.setFieldValue("stages", stagesArr, true)
	// 	let selectedHomesId = []
	// 	if (stagesArr && stagesArr.length > 0) {
	// 		homeQuery?.data.forEach((item) => {
	// 			if (
	// 				stagesArr.includes(item.stage) &&
	// 				item.status !== HOME_TYPE.ORDERED.code &&
	// 				item.status !== HOME_TYPE.TIME.code
	// 			) {
	// 				selectedHomesId.push(item.id)
	// 				document
	// 					.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 					.classList.add("item-selected")
	// 			} else {
	// 				console.log();
	// 				document
	// 					.querySelector(`.block-${id}-home#home-${item?.id}`)
	// 					.classList.remove("item-selected")
	// 			}
	// 		})
	// 	} else {
	// 		resetDisabling()
	// 	}

	// 	setSelectedHomes(selectedHomesId)
	// }
	// const handleSelectionType = (value) => {
	// 	setSelectionType(value)
	// 	resetDisabling()
	// 	formik.resetForm()
	// 	formikStages.resetForm()
	// 	if (value === "SQUARE_AND_STAGE") {
	// 		setHomeStageSelectOptions([])
	// 	} else {
	// 		setHomeStageSelectOptions(
	// 			Array.from(new Set(homeQuery?.data.map((item) => item.stage)))
	// 				.sort((a, b) => a - b)
	// 				.map((item) => ({
	// 					value: item,
	// 					label: item.toString()
	// 				}))
	// 		)
	// 	}
	// }

	useEffect(() => {

		setComponent(
			<div className="flex flex-row items-center w-full">
				<BackButton />
				{blockQuery?.data && (
					<div className="text-xl">
						<span className="text-base-color">
							{blockQuery?.data?.objects?.name} | {blockQuery?.data?.name}
						</span>
					</div>
				)}
			</div>
		)
	}, [blockQuery?.data, i18n.language])
	
	


	const toogleSelection = (ids)=>{
		document
		.querySelector(`.block-${id}-home#home-${ids}`)
		.classList.toggle("item-selected")
		setSelectedHomes([id])
		if(selectedHomes.find((item) => item == ids)){
			selectedHomes.splice(selectedHomes.map((el) => el).indexOf(ids), 1)
		}
		else{
			selectedHomes.push(ids)
			setMultipleHomeValuesToForm(ids)
		}
		setSelectedHomes(selectedHomes)

		console.log(selectedHomes);
	}

	const checkedStage = (stageNumber, checked) =>{
		
		const filtered = homeQuery?.data.filter(item => item.stage == stageNumber);
		if(checked){
				filtered?.forEach((item) => {
					if(!selectedHomes.includes(item.id)){
						selectedHomes.push(item.id)
						setSelectedHomes(selectedHomes)
						document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.add("item-selected")
					}
					})

				}
		else{
			filtered?.forEach((item) => {
				if(selectedHomes.includes(item.id)){
				selectedHomes.splice(selectedHomes.map((el) => el).indexOf(item.id), 1)
				setSelectedHomes(selectedHomes)
				document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.remove("item-selected")
				}
			})
		}

		console.log(selectedHomes);
	}



	const checkedSquare = (squareNumber, checked) =>{
		let stages = Array.from(new Set(homeQuery?.data.map((item) => item.stage)))
			const result = stages.map(stage => {
			  const filtered = homeQuery?.data.filter(item => item.stage == stage).sort((x, y) =>x.stage>=1 && +x.number - +y.number);
			  return filtered[squareNumber];
			});
		if(checked){
			result?.filter(undefin => undefin !== undefined).forEach((item) => {
					if(!selectedHomes.includes(item.id)){
						selectedHomes.push(item.id)
						setSelectedHomes(selectedHomes)

						document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.add("item-selected")
					}
					})
				

				}
		else{
						
			result?.filter(undefin => undefin != undefined).forEach((item) => {
				
					if(selectedHomes.includes(item.id)){
					selectedHomes.splice(selectedHomes.map((el) => el).indexOf(item.id), 1)
						setSelectedHomes(selectedHomes)
					document
					.querySelector(`.block-${id}-home#home-${item?.id}`)
					.classList.remove("item-selected")
					}
				})
			

			}
	}
		
		const checkAllCheckbox = (checked) =>{
			homeQuery?.data?.forEach((item) => {
			if(checked){
				selectedHomes.push(item.id)
						setSelectedHomes(selectedHomes)
				document
				.querySelector(`.block-${id}-home#home-${item?.id}`)
				.classList.add("item-selected")
			
			}
			else{
				selectedHomes.splice(selectedHomes.map((el) => el).indexOf(item.id), 1)
						setSelectedHomes(selectedHomes)
				document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.remove("item-selected")
			}
		})
		}	
		useEffect(()=>{
			const lot = setTimeout(() => {
				homeQuery?.data?.forEach((item) => {
					if(selectedHomes.length > 0){
						if (
							
							selectedHomes.includes(item.id)
						) {
		
							document
							.querySelector(`.block-${id}-home#home-${item?.id}`)
							.classList.add("item-selected")
						}
					}
					else{
						console.log(item);
						document
							.querySelector(`.block-${id}-home#home-${item?.id}`)
							.classList.remove("item-selected")
					}
						
					})
			}, 1000);
			clearTimeout(lot)
		},[selectedHomes,homeQuery?.data])

		const classes = Array.from({ length: 50 }, (v, i) => i);
		useEffect(()=>{
			const sheetColumns = document.querySelectorAll(".sheet-column")
			sheetColumns.forEach((sheetCol)=>{
				const divs = sheetCol.querySelectorAll(".sheet-home-row")
				divs.forEach(div=>{
					classes.forEach(className => {
						const elements = div.querySelectorAll(`.padez_id_${className}`);
						if (elements.length > 0) {
							elements[elements.length - 1].classList.add(`last-of-class-${className}`);
						}
						
					});
				})
				classes.forEach(className => {
					const element = divs[0].querySelectorAll(`.padez_id_${className}`);
					if (element.length > 0) {
						element[0].classList.add(`first-of-class`);
						element[0].setAttribute("padyezd", t("padyezd.padez",{indexPadez: className}))
					}
					
				});
			})
		},[classes])

			const getMaxFloorCount = (data) => {
				// Create a map to store the count of each floor
				const floorCount = data.reduce((acc, curr) => {
					acc[curr.stage] = (acc[curr.stage] || 0) + 1;
					return acc;
				}, {});
			
				// Find the maximum count
				const maxFloor = Object.keys(floorCount).reduce((max, floor) => {
					return floorCount[floor] > floorCount[max] ? floor : max;
				});
			
				return  { floor: Number(maxFloor), count: floorCount[maxFloor] };
			};
			
			console.log(formik.values.homeStatus);
	return (
		<div className="flex">
			<div className="sheet-type-tabs is-mini">
				<div className="flex mb-1 items-center">
					<div className="flex items-center justify-between w-full ml-4 flex-wrap">
						<div className="flex mt-2">
							<div className="flex items-center">
								<div className="w-4 h-4 rounded-sm bg-gray-400 mr-1"></div>
								{t("shaxmatka.homeType.disabled")}
							</div>
							<div className="flex ml-4 items-center">
								<div className="w-4 h-4 rounded-sm bg-orange-400 mr-1"></div>
								{t("shaxmatka.homeType.ordered")}
							</div>
							<div className="flex ml-4 items-center">
								<div className="w-4 h-4 rounded-sm bg-red-500 mr-1"></div>
								{t("shaxmatka.homeType.sold")}
							</div>
						</div>
					</div>
				</div>

				<div className="sheet-wrapper">
					<div className="sheet-base-area">
						<div className="sheet-grid ml-0 pl-0" 
							style={
								{
									marginLeft: "0px",
									paddingLeft: "0px",
									paddingBottom: "0px"
								}
							}
						>
							{(blockQuery.isLoading || blockQuery.isFetching) &&
							(homeQuery.isLoading || homeQuery.isFetching) ? (
								<div className="circular-progress-box min-h-[500px] h-full w-full">
									<CircularProgress size={50} />
								</div>
							) : (
								homeQuery?.data &&
								homeQuery?.data.length > 0 && (
									<div
										className="sheet-column"
										key={`block-${id}-columns`}
										id={`block-${id}-columns`}
									>
										{/* <div className="my-2 ml-10 leading-4">
											<span className="font-medium text-lg text-line-1">
												{blockQuery?.data?.name}
											</span>
											<span className="text-sm leading-4">
												({t("shaxmatka.homeType.free")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
												{t("shaxmatka.homeType.ordered")}:{" "}
												{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
												{t("shaxmatka.homeType.sold")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
											</span>
										</div> */}
												<Grid item={true} sm={1} xs={1} className="flex w-16 pl-2 items-center mt-0 sticky left-0 mr-3 bg-white z-30" >
														<div className="flex items-center translate-y-[6px]">
															
														all
														<Checkbox  onChange={(e)=>{
																checkAllCheckbox(e.target.checked)
														}} />	
														</div>
												</Grid>

										{getFullFloorsNumberByBlock().map((floorNumber, index) => (
											<div
												className={`sheet-row sheet-row-change ml-0 floor-${floorNumber}`}
												style={{
													paddingBottom: "0px"
												}}
												key={`block-${id}-row-${index}`}
												id={`block-${id}-row-${index}`}
											>

												<Grid
													container
													rowSpacing={1}
													columns={{
														xs: parseInt(
															new Set(homeQuery?.data.map((item) => item.stage))
																.size
														),
														sm: parseInt(
															new Set(homeQuery?.data.map((item) => item.stage))
																.size
														)
													}}
													key={`container-${floorNumber}`}
													className="sheet-home-row mx-0 px-04"
													style={{
														marginTop: '0px',
														marginBottom: '0px'
													}}
												>
													<Grid item={true} sm={1} xs={1} className="flex pb-0 w-16 pl-2 items-center sticky left-0 mr-3 bg-white z-30" >
														
															{floorNumber}
															<Checkbox className="checkChangeShax" onChange={(e)=>{
																	checkedStage(floorNumber,e.target.checked)
															}} />	
													</Grid>
													{homeQuery?.data.length > 0
														? homeQuery?.data
																.filter((home) => home.stage === floorNumber).sort((x, y) =>x.stage>=1 && +x.number - +y.number)
																.map((item, index) =>
																	item?.contract?.id ? (
																		<Grid
																			item={true}
																			sm={1}
																			xs={1}
																			key={`home-${item.id}`}
																			className={`sheet-home-cell floor-${floorNumber} padez_id_${item.padez}`}
																		>
																			<ButtonBase
																				onClick={()=>{
																					toogleSelection(item?.id, item?.block_id,item?.stage)
																				}}
																				className={`block-${id}-home home-item status-${
																					item.status
																				}${
																					item.disabled ? " is-disabled" : ""
																				}`}
																				id={`home-${item.id}`}
																			>
																				{!(
																					item.stage && parseInt(item.stage) < 0
																				) ? (
																					<Fragment>
																						<div className="home-item-data">
																							<span className="whitespace-nowrap mr-1">
																								№: {item.number}
																							</span>
																							{item?.contract?.sum &&
																								item?.contract?.discount &&
																								item?.contract?.square && (
																									<NumericFormat
																										value={
																											(parseFloat(
																												item.contract.sum
																											) -
																												parseFloat(
																													item.contract.discount
																												)) /
																											parseFloat(
																												item.contract.square
																											)
																										}
																										allowNegative={false}
																										displayType={"text"}
																										thousandSeparator={" "}
																										decimalScale={1}
																										className="w-full text-right"
																										suffix={
																											item?.isvalute === "1"
																												? " $"
																												: ""
																										}
																									/>
																								)}
																						</div>
																						<div className="home-item-data">
																							{item?.contract?.sum &&
																								item?.contract?.discount && (
																									<NumericFormat
																										value={
																											item.contract.sum -
																											item.contract.discount
																										}
																										allowNegative={false}
																										displayType={"text"}
																										thousandSeparator={" "}
																										decimalScale={1}
																										className="w-full text-center"
																										suffix={
																											item?.isvalute === "1"
																												? " $"
																												: " UZS"
																										}
																									/>
																								)}
																						</div>
																						<div className="home-item-data">
																							<span>{item.rooms}x</span>
																							<span>
																								<NumericFormat
																									value={item?.contract?.square}
																									allowNegative={false}
																									displayType={"text"}
																									decimalScale={2}
																									className="w-full text-right"
																								/>
																								<Trans i18nKey="common.global.meter">
																									m<sup>2</sup>
																								</Trans>
																							</span>
																						</div>
																					</Fragment>
																				) : (
																					<div className="h-[68px] flex items-center justify-center text-xl">
																						P
																					</div>
																				)}
																			</ButtonBase>
																		</Grid>
																	) : (
																		<Grid
																			item={true}
																			sm={1}
																			xs={1}
																			key={`home-${item.id}`}
																			className={`sheet-home-cell floor-${floorNumber}  padez_id_${item.padez}`}
																		>

																			<ButtonBase
																			onClick={()=>{
																				toogleSelection(item?.id, item?.block_id,item?.stage)
																			}}
																				className={`block-${id}-home home-item status-${
																					item.status
																				}${
																					item.disabled ? " is-disabled" : ""
																				}`}
																				id={`home-${item.id}`}
																			>
																				{!(
																					item.stage && parseInt(item.stage) < 0
																				) ? (
																					<Fragment>
																						<div className="home-item-data">
																							<span className="whitespace-nowrap">
																								№: {item.number}
																							</span>
																							{item.isrepaired ===
																							REPAIR_TYPE.REPAIRED.code ? (
																								<NumericFormat
																									value={item.repaired || ""}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-right"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: ""
																									}
																								/>
																							) : (
																								<NumericFormat
																									value={item.norepaired || ""}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-right"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: ""
																									}
																								/>
																							)}
																						</div>
																						<div className="home-item-data">
																							{item.isrepaired ===
																							REPAIR_TYPE.REPAIRED.code ? (
																								<NumericFormat
																									value={
																										item.repaired && item.square
																											? formatMultiplies(
																													[
																														item.repaired,
																														item.square
																													],
																													2
																											  )
																											: 0
																									}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-center"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: " UZS"
																									}
																								/>
																							) : (
																								<NumericFormat
																									value={
																										item.norepaired &&
																										item.square
																											? formatMultiplies(
																													[
																														item.norepaired,
																														item.square
																													],
																													2
																											  )
																											: 0
																									}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-center"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: " UZS"
																									}
																								/>
																							)}
																						</div>
																						<div className="home-item-data">
																							<span>{item.rooms}x</span>
																							<span>
																								<NumericFormat
																									value={item?.square}
																									allowNegative={false}
																									displayType={"text"}
																									decimalScale={2}
																									className="w-full text-right"
																								/>{" "}
																								<Trans i18nKey="common.global.meter">
																									m<sup>2</sup>
																								</Trans>
																							</span>
																						</div>
																					</Fragment>
																				) : (
																					<div className="h-[68px] flex items-center justify-center text-xl">
																						P
																					</div>
																				)}
																			</ButtonBase>
																		</Grid>
																	)
																)
														: [1].map((item) => (
																<Grid
																	item={true}
																	sm={1}
																	xs={1}
																	key={`home-empty-${item}`}
																	className={`sheet-home-cell sheet-home-empty-cell floor-${floorNumber}`}
																>
																	<ButtonBase className="home-item is-empty">
																		<div className="home-item-data"></div>
																	</ButtonBase>
																</Grid>
														  ))}
												</Grid>
											</div>
										))}
										{/* <div className="mt-5 ml-10 leading-4">
											<span className="font-medium text-lg text-line-1">
												{blockQuery?.data?.name}
											</span>
											<span className="text-sm leading-4">
												({t("shaxmatka.homeType.free")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
												{t("shaxmatka.homeType.ordered")}:{" "}
												{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
												{t("shaxmatka.homeType.sold")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
											</span>
										</div> */}
										<Grid
										container
										rowSpacing={1}
										columns={{
											xs: parseInt(
												new Set(homeQuery?.data.map((item) => item.stage))
													.size
											),
											sm: parseInt(
												new Set(homeQuery?.data.map((item) => item.stage))
													.size
											)
										}}
										key={`container-lastCheck`}
										className="sheet-home-row mx-0 px-04 sticky bottom-[-1px] z-10"
										style={{
											marginTop: '0px',
											marginBottom: '0px',
											paddingTop: "0px"
										}}>
											<Grid  className="flex pb-0 w-16 pl-2 items-center sticky left-0 bg-white z-50">
											</Grid>
											
											<div className="flex bg-white mt-2">
											{
												Array.from({ length: getMaxFloorCount(homeQuery?.data).count }, (v, i) => i).map((item,index)=>(
													<Grid
													width={130}
													marginLeft={0.5}
													marginRight={0.5}
																			key={`home-${item}`}
																			className={`text-center bg-[#fff]  padez_id_${homeQuery.data.filter(prev=> prev.stage == getMaxFloorCount(homeQuery?.data).floor)[index].padez}`}>
													<div>
														<Checkbox onClick={(e)=>{
															checkedSquare(item,e.target.checked)
														}} />
													</div>
													</Grid>
												))
											}
											</div>
											
										</Grid>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="sheet-actions-area is-full not-full">
				<div className="sheet-actions-body relative" style={{height: "calc(100vh - 100px)"}}>
					<div className="sheet-form-action">
						<form
						 className="relative"
								onSubmit={
										formik.handleSubmit
								}
						>
							<Grid
								container
								spacing={2}
								rowSpacing={1}
								columns={{ xs: 12, sm: 12 }} 
								className="relative"
							><Fragment>
								<Grid
									item={true}
									sm={12}
									sx={{ marginBottom: "-0.5rem" }}
								>
									<FormTextField
										delay={0}
										label={t("common.fields.homeNumber")}
										fieldName="number"
										formik={formik}
									/>
								</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormNumberField
									delay={0}
									label={t("common.fields.rooms")}
									fieldName="rooms"
									formik={formik}
								/>
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormNumberField
									delay={0}
									label={t("common.fields.homeAreAll")}
									fieldName="square"
									formik={formik}
									decimalScale={2}
								/>
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormSimpleSelectField
									delay={0}
									fieldName="isvalute"
									formik={formik}
									label={t("common.fields.currency")}
									options={currencyTypeSelectOptions}
									itemValue="code"
									itemLabel="label"
									changeFn={handleCurrencyChange}
								/>
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormCurrencyField
									delay={0}
									fieldName="repaired"
									formik={formik}
									label={
										<span>
											<Trans i18nKey="common.fields.repairedPrice">
												Ta'mirlangan narxi(1 m<sup>2</sup>)
											</Trans>
										</span>
									}
									decimalScale={1}
								/>
								{formik.values.isvalute === "1" &&
									!isNaN(formik.values.repaired) && (
										<CurrencySubContent
											value={formik.values.repaired || "0"}
										/>
									)}
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormCurrencyField
									delay={0}
									fieldName="norepaired"
									formik={formik}
									label={
										<span>
											<Trans i18nKey="common.fields.noRepairedPrice">
												Ta'mirlanmagan narxi(1 m<sup>2</sup>)
											</Trans>
										</span>
									}
									decimalScale={1}
								/>
								{formik.values.isvalute === "1" &&
									!isNaN(formik.values.norepaired) && (
										<CurrencySubContent
											value={formik.values.norepaired || "0"}
										/>
									)}
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "-0.5rem" }}
							>
								<FormCurrencyField
									delay={0}
									fieldName="start"
									formik={formik}
									label={t("common.fields.startPrice")}
									decimalScale={1}
								/>
								{formik.values.isvalute === "1" &&
									!isNaN(formik.values.start) && (
										<CurrencySubContent
											value={formik.values.start || "0"}
										/>
									)}
							</Grid>

							<Grid item={true} sm={12}>
								<FormRadioGroupField
									delay={0}
									fieldName="islive"
									formik={formik}
									
									label={t("common.fields.residentType")}
									options={residentTypeSelectOptions}
								/>
							</Grid>

							<Grid
								item={true}
								sm={12}
								sx={{ marginTop: "-0.5rem", marginBottom: "-0.5rem" }}
							>
								<FormRadioGroupField
									delay={0}
									fieldName="isrepaired"
									formik={formik}
									label={t("common.fields.repairType")}
									options={repairTypeSelectOptions}
								/>
							</Grid>

								{/* <Grid item={true} sm={12}>
									<SimpleCheckbox
										delay={0}
										duration={0}
										label={t("shaxmatka.withPlan")}
										value={editWithPlanActive}
										setValue={handleWithPlanActive}
									/>
								</Grid> */}

								<Grid item={true} sm={12}>
									<FormLabel id={`link-radio-buttons-group`}>Binoda joylashuvi</FormLabel>
									<FormFileUploadField
										delay={0.2}
										accept=".jpg, .png"
										fieldName="link"
										formik={formik}
										setOpen={setOpenHomeLayoutImageDialog}
										seturl={setUrlImage}
										label={t("common.fields.plan")}
										btnLabel={t("Rasmni yuklash")}
									/>
								</Grid>

						<Grid item={true} sm={12}>
							<FormLabel id={`Dimage-radio-buttons-group`}>Binoda joylashuvi</FormLabel>
							<FormFileUploadField
								delay={0.2}
								accept=".jpg, .png"
								fieldName="Dimage"
								formik={formik}
								setOpen={setOpenHomeLayoutImageDialog}
								seturl={setUrlImage}
								label={t("common.fields.plan")}
								btnLabel={t("Rasmni yuklash")}
							/>
						</Grid>
						<Grid item={true} sm={12}>
							<FormLabel id={`addressimage-radio-buttons-group`}>Binoda joylashuvi</FormLabel>
							<FormFileUploadField
								delay={0.2}
								accept=".jpg, .png"
								fieldName="addressimage"
								formik={formik}
								setOpen={setOpenHomeLayoutImageDialog}
								seturl={setUrlImage}
								label={t("common.fields.plan")}
								btnLabel={t("Rasmni yuklash")}
							/>
						</Grid>
							<Grid
								item={true}
									sm={12}
									sx={{ marginBottom: "-0.5rem" }}
								>
									<FormTextField
										delay={0}
										label={t("common.fields.homeNumber")}
										fieldName="uid"
										formik={formik}
									/>
							</Grid>
							<Grid
								item={true}
								sm={12}
								sx={{ marginBottom: "3rem" }}
							>
								<FormSimpleSelectField
									delay={0}
									fieldName="homeStatus"
									formik={formik}
									label={t("common.filter.repairType")}
									options={homeAllTypeSelectOptions}
									itemValue="code"
									itemLabel="label"
									iLang={true}
									// changeFn={handleCurrencyChange}
								/>
							</Grid>
						</Fragment>
					<Grid item={true} sm={12} xs={12} className="fixed rounded-b-[0.5rem] w-full bottom-0 flex items-center justify-center h-[60px] pb-7 z-10 bg-white">
						<FormActionButtons
							delay={0}
							isSubmitting={false}
							onlySave
							className="-mt-0.5 -ml-1"
						/>
					</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</div>
			{openHomeLayoutImageDialog && (
				<ImagePreviewDialog
					open={openHomeLayoutImageDialog}
					setOpen={setOpenHomeLayoutImageDialog}
					urls={[urlImage]}
					blockImageDialog="true"
				/>
			)}
		</div>
	)
}

export default BlockEdit
