import { Button, Chip, Divider } from "@mui/material"
import ImagePreviewDialog from "components/ui/dialogs/ImagePreviewDialog"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import PhoneFormat from "components/ui/text-formats/PhoneFormat"
import useNavigationByRole from "hooks/useNavigationByRole"
import OrderAddModal from "pages/admin/order/OrderAddModal"
import { Fragment, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { Link } from "react-router-dom"
import HOME_TYPE from "shared/homeTypeList"
import {
	homeTypeVariants,
	repairTypeVariants,
	residentTypeVariants
} from "shared/tableColVariantsList"
import ShaxmatkaMoreHomeDetailsModal from "./ShaxmatkaMoreHomeDetailsModal"
import PlanoplanWidget from "components/Planoplan/PlanoplanWidget"
import { useQueries, useQuery } from "react-query"
import useAxiosPrivate from "hooks/useAxiosPrivate"

const ShaxmatkaHomeDetail = ({
	selectedHome = [],
	blocks = [],
	refetchFn = () => {},
	orderHome = false,
	showContract = false,
	onlyHomeDetails = false
}) => {
	const { linkFn } = useNavigationByRole()
	const { t } = useTranslation()
	const [homeData, setHomeData] = useState({})
	const [blockName, setBlockName] = useState({})
	const [refetch, setRefetch] = useState(false)
	const [openOrderModal, setOpenOrderModal] = useState(false)
	const [typeModelVisual, setTypeModelVisual] = useState(1)
	const axiosPrivate = useAxiosPrivate()
    const [hasError, setHasError] = useState(false)
	const [openMoreDetailModal, setOpenMoreDetailModal] = useState(false)
	const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
		useState(false)
	useEffect(() => {
		if (selectedHome.length > 0 && blocks.length > 0) {
			setHomeData(
				blocks[selectedHome[0].blockIndex].homes.find(
					(item) => item.id === selectedHome[0].id
				)
			)
			setBlockName(blocks[selectedHome[0].blockIndex]?.name)
		}
	}, [selectedHome])
	const [homeDetailQuery] = useQueries([{
		queryKey: ["HomeDetailIdKey", selectedHome[0].id],
		queryFn: async function () {
			const response = await axiosPrivate.get(`admin/home/view/${selectedHome[0].id}`)
			return response?.data?.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
			retry: false
	}])
	useEffect(() => {
		if (selectedHome.length === 0 || !selectedHome[0].id) {
			setHasError(true);
		} else {
			setHasError(false);
		}
	}, [selectedHome]);
	useEffect(() => {
		if (refetch) {
			refetchFn()
			setRefetch(false)
		}
	}, [refetch])
	console.log(selectedHome);
	const setHomeType = (item, typeArr) => {
		let result = typeArr.filter((variant) => variant.code === item)
		if (result.length > 0) {
			return (
				<Chip
					label={t(result[0].label)}
					variant="tableBadge"
					color={result[0].color}
				/>
			)
		}
		return ""
	}
	const changeTypeStyleVisual = (e, index)=>{
		const detail = document.querySelector(".back_model_type")
		detail.style.left = e.target.offsetLeft+"px"
		detail.style.width = e.target.offsetWidth+"px"
		// modelRefShax.current.offsetWidth = 42
		index == 1 ? setTypeModelVisual(1) : index == 2 ? setTypeModelVisual(2): setTypeModelVisual(3)
	}

	return (
		<div className="sheet-actions-home-detail-wrapper">
			{blockName && Object.keys(homeData).length > 0 && (
				<Fragment>
					{homeDetailQuery?.data?.status && homeDetailQuery?.data.status === HOME_TYPE.ACTIVE.code && (
						<Fragment>
							<div className="mb-3">
								<Button
									color="success"
									variant="contained"
									fullWidth
									onClick={() => setOpenMoreDetailModal(true)}
								>
									{t("shaxmatka.homeDetail.more")}
								</Button>
							</div>
							<Divider />
						</Fragment>
					)}

					<div className="home-detail-header mt-3">
						<div className="home-detail-title">
							{t("shaxmatka.homeDetail.title", {
								blockName: blockName,
								homeNumber: homeData?.number
							})}
						</div>
					</div>
					<div className={`home-detail-body duration-300 ${typeModelVisual == 3 ? "detailTypeVis" : ""}`}>
							<div className="home-detail home-image-wrapper cursor-pointer">
								{
									typeModelVisual == 1?
									<img
									src={`${process.env.REACT_APP_BACKEND_URL}/${homeDetailQuery?.data?.homes? homeDetailQuery?.data?.homes?.plan?.link : homeDetailQuery?.data?.plan?.link}`}
									alt={homeDetailQuery?.data?.plan?.name+"2d" || "home-image"}
									onClick={() => setOpenHomeLayoutImageDialog(true)}
								/>:
								typeModelVisual == 2?
								<img
									src={`${process.env.REACT_APP_BACKEND_URL}/${homeDetailQuery?.data?.homes ?homeDetailQuery?.data?.homes?.plan?.Dimage : homeDetailQuery?.data?.plan?.Dimage}`}
									alt={homeDetailQuery?.data?.plan?.name+"3d" || "home-image"}
									onClick={() => setOpenHomeLayoutImageDialog(true)}
								/>:
									<PlanoplanWidget />
								}
								<div className={`absolute  p-[2px] z-10 left-[50%] ${typeModelVisual == 3 ? "bottom-[10px]" : "bottom-4" } bottom-4 translate-x-[-50%] rounded-[5px] bg-[#32064a3e]`}>
									<div className="relative flex gap-[2px]">
										
										<span onClick={(e)=>{
											changeTypeStyleVisual(e,1)
										}} className="text-[#00a388] py-[5px] px-4 inline-block">2D</span>
										<span onClick={(e)=>{
											changeTypeStyleVisual(e,2)
										}} className="text-[#00a388] py-[5px] px-4 inline-block">3D</span>
										<span onClick={(e)=>{
											changeTypeStyleVisual(e,3)
										}} className="text-[#00a388] text-nowrap py-[5px] px-4 inline-block">3D tur</span>
										<div className="absolute left-0 top-0 h-full w-[49px] duration-300 bg-white rounded-[3px]  z-[-1] back_model_type"></div>
									</div>
								</div>
							</div>
							{/* <div className="home-detail home-image-wrapper">
								<img
									src={require("assets/images/placeholder-image.jpg")}
									alt="placeholder-image"
								/>
							</div> */}
						{!onlyHomeDetails && homeDetailQuery?.data?.client_id &&  homeDetailQuery?.data?.status && homeDetailQuery?.data?.status !== "3" && (
							<Fragment>
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.contractNumber")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.name || "—"}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.custom")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.custom ? (
											<Link
												to={linkFn(
													`/BASE/client/view/${homeDetailQuery?.data.custom?.id}`
												)}
												className="underline font-medium"
											>
												{homeDetailQuery?.data.custom?.surname}{" "}
												{homeDetailQuery?.data.custom?.name}
											</Link>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.phone")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.custom?.phone ? (
											<PhoneFormat value={homeDetailQuery?.data.custom.phone} />
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								{homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.custom?.phone2 && (
									<Fragment>
										<div className="home-detail">
											<div className="home-detail-item">
												{t("shaxmatka.homeDetail.phone2")}:
											</div>
											<div className="home-detail-item-value">
												<PhoneFormat value={homeDetailQuery?.data.custom.phone2} />
											</div>
										</div>
										<Divider />
									</Fragment>
								)}
							</Fragment>
						)}

						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.homeNumber")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.client_id ? homeDetailQuery?.data?.homes?.number : homeDetailQuery?.data?.number || "—"}
							</div>
						</div>
						<Divider />
						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.stage")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.client_id ? homeDetailQuery?.data?.homes?.stage : homeDetailQuery?.data?.stage || "—"}
							</div>
						</div>
						<Divider />
						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.rooms")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.client_id ? homeDetailQuery?.data?.homes?.rooms : homeDetailQuery?.data?.rooms || "—"}
							</div>
						</div>
						<Divider />

						{!onlyHomeDetails && homeDetailQuery?.data?.contract?.id ? (
							<Fragment>
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.areaAll")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.square ? (
											<>
												<NumericFormat
													value={homeDetailQuery?.data?.square}
													allowNegative={false}
													displayType={"text"}
													decimalScale={2}
													className="w-full text-right"
												/>{" "}
												<Trans i18nKey="common.global.meter">
													m<sup>2</sup>
												</Trans>
											</>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										<Trans i18nKey="shaxmatka.homeDetail.priceForSquare">
											m<sup>2</sup> narxi
										</Trans>
										:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.client_id &&
										homeDetailQuery?.data?.sum &&
										homeDetailQuery?.data?.discount &&
										homeDetailQuery?.data?.square ? (
											<CurrencyFormat
												value={
													(parseFloat(homeDetailQuery?.data.sum) -
														parseFloat(homeDetailQuery?.data.discount)) /
													parseFloat(homeDetailQuery?.data.square)
												}
												suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.startPrice")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.client_id && homeData?.start_price ? (
											<CurrencyFormat
												value={homeDetailQuery?.data.start_price}
												suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : (
											"—"
										)}
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.areaAll")}:
									</div>
									<div className="home-detail-item-value">
										{homeDetailQuery?.data?.square ? (
											<>
												<NumericFormat
													value={homeDetailQuery?.data?.square}
													allowNegative={false}
													displayType={"text"}
													decimalScale={2}
													className="w-full text-right"
												/>{" "}
												<Trans i18nKey="common.global.meter">
													m<sup>2</sup>
												</Trans>
											</>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										<Trans i18nKey="shaxmatka.homeDetail.repaired">
											m<sup>2</sup> ta'mirli
										</Trans>
										:
									</div>
									<div className="home-detail-item-value">
										{ homeDetailQuery?.data?.homes?.repaired ? ( 
											<CurrencyFormat
												value={homeDetailQuery?.data?.homes?.repaired}
												suffix={homeDetailQuery?.data?.homes?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : homeDetailQuery?.data?.repaired ? (
											<CurrencyFormat
												value={homeDetailQuery?.data?.repaired}
												suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										<Trans i18nKey="shaxmatka.homeDetail.norepaired">
											m<sup>2</sup> ta'mirsiz
										</Trans>
										:
									</div>
									<div className="home-detail-item-value">
										{ homeDetailQuery?.data?.homes?.norepaired ? ( 
											<CurrencyFormat
												value={homeDetailQuery?.data?.homes?.norepaired}
												suffix={homeDetailQuery?.data?.homes?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : homeDetailQuery?.data?.norepaired? (
											<CurrencyFormat
												value={homeDetailQuery?.data?.client_id ? homeDetailQuery?.data.homes.norepaired:homeDetailQuery?.data.norepaired}
												suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : (
											"—"
										)}
									</div>
								</div>
								<Divider />
								<div className="home-detail">
									<div className="home-detail-item">
										{t("shaxmatka.homeDetail.startPrice")}:
									</div>
									<div className="home-detail-item-value">
										{ homeDetailQuery?.data?.start_price ? (
											<CurrencyFormat
											value={homeDetailQuery?.data?.start_price}
											suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
											decimalScale={1}
										/>
										) : homeDetailQuery?.data?.start ? (
											<CurrencyFormat
												value={homeDetailQuery?.data.start}
												suffix={homeDetailQuery?.data?.isvalute === "1" ? " $" : " UZS"}
												decimalScale={1}
											/>
										) : (
											"—"
										)}
									</div>
								</div>
							</Fragment>
						)}

						<Divider />
						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.residentType")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.client_id ? setHomeType(homeDetailQuery?.data.homes.islive, residentTypeVariants) :homeDetailQuery?.data?.islive
									? setHomeType(homeDetailQuery?.data.islive, residentTypeVariants)
									: "—"}
							</div>
						</div>
						<Divider />
						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.repairType")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.isrepaired
									? setHomeType(homeDetailQuery?.data.isrepaired, repairTypeVariants)
									: "—"}
							</div>
						</div>
						<Divider />
						<div className="home-detail">
							<div className="home-detail-item">
								{t("shaxmatka.homeDetail.position")}:
							</div>
							<div className="home-detail-item-value">
								{homeDetailQuery?.data?.client_id ?setHomeType(homeDetailQuery?.data.homes.status, homeTypeVariants) : homeDetailQuery?.data?.status
									? setHomeType(homeDetailQuery?.data.status, homeTypeVariants)
									: "—"}
							</div>
						</div>
					</div>
					{orderHome &&
						homeDetailQuery?.data?.status &&
						homeDetailQuery?.data.status === HOME_TYPE.ACTIVE.code &&
						!(homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.id) && (
							<div className="mt-8">
								<Button
									color="success"
									variant="contained"
									fullWidth
									onClick={() => setOpenOrderModal(true)}
								>
									{t("shaxmatka.homeDetail.orderHome")}
								</Button>
							</div>
						)}
						
					{showContract && homeDetailQuery?.data?.client_id && homeDetailQuery?.data?.id && (
						<div className="mt-8">
							<Link
								to={linkFn(`/BASE/contract/view/${homeDetailQuery?.data.id}`)}
								className="no-underline"
							>
								<Button color="info" variant="outlined" fullWidth>
									{t("shaxmatka.homeDetail.goToContract")}
								</Button>
							</Link>
						</div>
					)}
				</Fragment>
			)}

			{openOrderModal && (
				<OrderAddModal
					open={openOrderModal}
					setOpen={setOpenOrderModal}
					data={homeDetailQuery?.data}
					setRefetch={setRefetch}
				/>
			)}

			{openMoreDetailModal && (
				<ShaxmatkaMoreHomeDetailsModal
					open={openMoreDetailModal}
					setOpen={setOpenMoreDetailModal}
					homeData={homeDetailQuery?.data}
				/>
			)}

				<ImagePreviewDialog
					open={openHomeLayoutImageDialog}
					setOpen={setOpenHomeLayoutImageDialog}
					urls={homeDetailQuery?.data?.client_id ? [homeDetailQuery?.data?.homes?.plan?.link,homeDetailQuery?.data?.homes?.plan?.Dimage, homeDetailQuery?.data?.homes?.plan?.addressimage] :[homeDetailQuery?.data?.plan?.link,homeDetailQuery?.data?.plan?.Dimage, homeDetailQuery?.data?.plan?.addressimage]}
				/>
		</div>
	)
}

export default ShaxmatkaHomeDetail
