import { Pie } from "react-chartjs-2"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js"
import "chart.js/auto"
import { numericFormatter } from "react-number-format"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const PieChart = ({
	chartDatasets = [],
	chartLabels = [],
	optionPlugins = {},
	options = {},
	plugins = []
}) => {
	return (
		<Pie
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
						position: "top",
						reverse: true,
						labels: {
							usePointStyle: true,
							pointStyle: "rectRounded"
						}
					},
					...optionPlugins
				},
				...options
			}}
			plugins={plugins}
			data={{ labels: chartLabels, datasets: chartDatasets }}
		/>
	)
}

export default PieChart
