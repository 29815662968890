import { Button, Chip, CircularProgress, Grid, Select, TextField } from '@mui/material'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useQueries } from 'react-query'
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import SearchInput from "components/SearchInput"
import { useTranslation } from 'react-i18next'
import MessageAddShablon from '../MessageAddShablon'
import { useSearchParams } from 'react-router-dom'
const MessageShablon = () => {
    const [hasError, setHasError] = useState(false)
   
    const [open, setOpen] = useState(false)
    const editableDivRef = useRef(null);
    const [refetch, setRefetch] = useState(false)
    const {t} = useTranslation()
    const axiosPrivate = useAxiosPrivate()
    const sendNotification = useNotification()
    const [queryPath, setQueryPath] = useState("/sms_text_templates")
    const [messageDetailsQuery, messageTemplateListQuery, messageHeadTypeQuery, messageTagsQuery] = useQueries([
    
		{
			queryKey: "messageDetails",
			queryFn: async function () {
				const response = await axiosPrivate.get(queryPath)
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "messageTemplateList",
			queryFn: async function () {
				const response = await axiosPrivate.get(queryPath)
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
    {
			queryKey: "messageHeadType",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_types")
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
    ,
    {
			queryKey: "messageTags",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_text_template_tags")
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])
  const initialState = messageHeadTypeQuery?.data?.reduce((acc, item) => {
    acc[item.type] = false;
    return acc;
  }, {});
  const [updateMessageShablon, setUpdateMessageShablon] = useState([initialState])

  const handleUpdateShablon = (event)=>{
    setUpdateMessageShablon(prevValues => (
      {...prevValues,
        [event]: prevValues[event]? false : true
      }
    ))
}
	const handleChangeAutoMessageSend = async (templateId,types) => {
		try {
			const response = await axiosPrivate.post(
				`sms_text_update/${templateId}`,
				JSON.stringify({text: editableDivRef?.current?.innerText,type: types}),
				{ headers: { "Content-Type": "application/json" } }
			)
			if (response.data) {
				messageDetailsQuery.refetch()
        handleUpdateShablon(types)
        sendNotification({
          msg: t("settings.message.serverDetailsAddEditModal.alerts.update"),
          variant: "success"})
			}
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
		}
	}
  const getHighlightedText = (input, type) => {
    const parts = input.split(/(&[^&]+&)/g);
    return parts.map((part, index) =>
      part.startsWith('&') && part.endsWith('&') ? (
        updateMessageShablon[type]?<span className='shablon_message' contentEditable="false">&{part.slice(1, -1)}&<i onClick={(e)=>deleteButton(e)} className="bi bi-x"></i></span>:
        <span key={index} style={{ color: '#8e2bc4' }}>
          &{part.slice(1, -1)}&
        </span>
      ) : (
        part
      )
    );
  };
  const insertButton = (val) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      // Ensure that the selection is within the contenteditable div
      if (editableDivRef.current.contains(range.commonAncestorContainer)) {
        // Create the button element
        const button = document.createElement('span');
        button.innerHTML = `&${val}& <i class="bi bi-x"></i>`;
        button.classList.add('shablon_message');
        button.setAttribute('contentEditable', 'false');
        // Attach the delete function to the icon
        button.querySelector('i').onclick = (e) => deleteButton(e, button);
        // Insert the button at the selection
        range.deleteContents();
        range.insertNode(button);

        // Clear the selection
        selection.removeAllRanges();
      }
    }
  };
  const deleteButton = (event) => {
    const button = event.target.parentElement;
    button.remove()
  };
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(()=>{
    refetch&&messageDetailsQuery.refetch()
  },[messageDetailsQuery])
  useEffect(() => {
		if (queryPath && queryPath.length > 0) {
			messageDetailsQuery.refetch()
		}
	}, [queryPath])

	useEffect(() => {
		createQueryPath()
	}, [searchParams,editableDivRef])
  const createQueryPath = () => {
		let newQueryPath = `/sms_text_templates`
		let localSearchParams = Object.fromEntries([...searchParams])
		Object.keys(localSearchParams).forEach((item, index) => {
			if (index === 0) {
				newQueryPath += `?${item}=${localSearchParams[item]}`
			} else {
				newQueryPath += `&${item}=${localSearchParams[item]}`
			}
		})
		setQueryPath(newQueryPath)
	}
  return (
    <div className="bg-[#fff] p-5 rounded-[7px]" style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
						<div className='flex items-center h-full'>
              <SearchInput inputKey="name"/>
              <div className="ml-auto flex flex-row">
              <Button
                variant="action"
                color="success"
                className="!mx-2"
                onClick={() => {
                  setOpen(true)
                }}
                >
                <i className="bi bi-plus-circle" />
              </Button>
              </div>
            </div>
    <Grid
        container
        spacing={{ xs: 2, sm: 3, lg: 3 }}
        rowSpacing={1}
        columns={{ xs: 12, sm: 12, lg: 12 }}
        >
          {messageDetailsQuery && (
                <Fragment>
       
        {
           
            messageDetailsQuery.isLoading && messageDetailsQuery.isFetching?
                <div className="flex items-center w-full h-full justify-center">
                  <CircularProgress size={27.5} color="inherit" />
                </div>:
                messageDetailsQuery?.data && messageDetailsQuery?.data.length > 0 ?(
                messageDetailsQuery?.data.map((item,index)=>(
                <Grid item={true} lg={12} sm={12} xs={12} key={index+"message_shablon_list_parent"}>
                   {updateMessageShablon[item.type]?
                   <div className="border p-5" style={{borderRadius: '5px',borderColor:"rgb(156 163 175)"}}>
                       <div 
                       className='border px-5 py-2' 
                       style={{
                           fontWeight: "500",
                           borderRadius: '3px',
                           display: 'inline-block',
                           borderColor:"rgb(156 163 175)"
                       }}>{messageHeadTypeQuery?.data?.filter(type=> type.type === item?.type).map(set=> set.text)}</div>
                       <div>
                   </div>
                   <div
                       className='border p-5 w-full mt-4' 
                       style={{
                           fontWeight: "500",
                           borderRadius: '3px',
                           display: 'inline-block',
                           borderColor:"rgb(156 163 175)"
                       }}
                       ref={editableDivRef}
                      contentEditable={true}
                      
                    >
                       <p style={{
                        outline: "none",
                        border: "none",
                        lineHeightStep: "2"
                    }} 
                    >{getHighlightedText(item?.text, item?.type)}</p>
                   </div>
                   <div className='flex items-center justify-between mt-4 gap-3'>
                    <div className='flex gap-3 flex-wrap'>
                      {messageTagsQuery?.data && messageTagsQuery?.data?.data.length > 0 &&
                       messageTagsQuery?.data?.data.filter(typeName=> typeName.sms_text_template_type === item.type).map((tags, index)=>(
                        <Button key={index+"tags_shablons"}  variant="contained" size='small' color="secondary" onClick={(e)=> insertButton(tags.tag)}>{tags.text}</Button>
                       ))}
                      
                    </div>
                    <div>
                    <Button
                               variant="action"
                               color="success"
                               onClick={() => {
                                handleChangeAutoMessageSend(item?.id, item?.type)
                               }}
                           >
                               <i
                                   className={`bi bi-check`}
                               />
                           </Button>
                    </div>
                   </div>
               </div>
                   :
                   <div className="border p-5" style={{borderRadius: '5px',borderColor:"rgb(156 163 175)"}} key={"parent_nonedit"+index}>
                <div className='flex items-center justify-between'>
                    <div style={{
                        fontWeight: "500",
                        paddingTop: "8px"
                    }}>{messageHeadTypeQuery?.data?.filter(type=> type.type === item?.type).map(set=> set.text)}</div>
                    <div>
                        <Button
							        variant="action"
							        color="info"
							        onClick={() => {
                        handleUpdateShablon(item?.type)
							        }}
						        >
							<i
								className={`bi bi-pencil-square`}
							/>
						</Button>
                    </div>
                </div>
                <div style={{
                        fontWeight: "400",
                        color: "rgb(156 163 175)",
                        marginTop: '6px'
                    }}>
                    <p className='leading-8'>{getHighlightedText(item?.text, item?.type)}</p>
                </div>
            </div>} 
                 
        </Grid>
            )))
            :<span className="no-data-found-wrapper flex justify-center w-full items-center h-full">
              <i className="bi bi-exclamation-octagon icon-xl" />{" "}
              {t("common.global.noDataFound")}
            </span>
        }
        </Fragment>
        )}
        </Grid>
        {open && (
				<MessageAddShablon
					open={open}
					setOpen={setOpen}
					setRefetch={setRefetch}
				/>
			)}
      </div>
  )
}

export default MessageShablon