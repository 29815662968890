import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton
} from "@mui/material"
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import FormMultilineTextField from "components/ui/form/FormMultilineTextField"
import FormSelectChangeFnField from "components/ui/form/FormSelectChangeFnField"
import FormSelectField from "components/ui/form/FormSelectField"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import PhoneFormat from "components/ui/text-formats/PhoneFormat"
const validationSchema = yup.object({
	sum: yup.number().required("expense.modal.validation.sum"),
	payment_type: yup.number().required("expense.modal.validation.paymentType"),
	comment: yup.string("expense.modal.validation.comment").notRequired(".").no,
	object_id: yup.string().notRequired(),
	reason_id: yup.string().required("Chiqim turi"),
	block_id: yup.string().notRequired()
})

const ExpenseAddModal = (props) => {
	const { open, setOpen, data, setRefetch } = props
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const formik = useFormik({
		initialValues: {
			sum: "",
			payment_type: "",
			comment: "",
			object_id: "",
			block_id: "",
			reason_id: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				let newValues = { ...values }
				if (newValues.object_id) {
					delete newValues.object_id
				}
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/accounter/rasxod/store",
					JSON.stringify(newValues),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data && response.data.status) {
					sendNotification({
						msg: t("expense.modal.alerts.success"),
						variant: "success"
					})
					setIsSubmitting(false)
					handleClose()
					setRefetch(true)
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const handleObjectChange = (value) => {
		formik.setFieldValue("object_id", value, true)
		formik.setFieldValue("block_id", "", true)
	}

	const handleClose = () => {
		setOpen(false)
		formik.resetForm()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="md"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="alert-dialog-title">
				<span className="pr-5">{t("Xabar yuborish")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<form onSubmit={formik.handleSubmit} className="pt-2">
					<Grid
						container
						spacing={{ xs: 2, sm: 2 }}
						rowSpacing={1}
						columns={{ xs: 12, sm: 12 }}
					>
						<Grid item={true} sm={6} xs={12}>
						<div className="home-data-item flex flex-col">
							<span className="data-item-title text-base font-medium text-base-color">
								{t("contract.view.clientPhysical.custom")}:
							</span>
							<span className="data-item-value text-base font-semibold">
								{data?.custom?.surname}{" "}
								{data?.custom?.name}{" "}
								{data?.custom?.middlename}
							</span>
						</div>
						</Grid>

						<Grid item={true} sm={6} xs={12}>
						<div className="home-data-item flex flex-col">
							<span className="data-item-title text-base font-medium text-base-color ">
								{t("contract.view.clientPhysical.phone")}:
							</span>
							<span className="data-item-value text-base flex flex-col font-semibold">
								<PhoneFormat
									value={data?.custom?.phone}
								/>
								<PhoneFormat
									value={data?.custom?.phone2}
								/>
							</span>
						</div>
						</Grid>
						<Grid item={true} sm={12} xs={12}>
							<FormMultilineTextField
								delay={0.1}
								label={t("Xabar matnini kiriting")}
								fieldName="comment"
								formik={formik}
								rows={5}
								required={false}
							/>
						</Grid>
						

						<Grid item={true} sm={12} xs={12}>
							<FormActionButtons
								delay={0.1}
								isSubmitting={isSubmitting}
								formType="dialog"
								setOpen={setOpen}
								reset={formik.resetForm}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default ExpenseAddModal
