import {
	CircularProgress,
	Dialog,
	DialogContent,
	IconButton,
	Zoom as TZoom
} from "@mui/material"
import React, { Fragment, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCoverflow, Zoom } from "swiper/modules"
import { useTranslation } from "react-i18next"

const ImagePreviewDialog = ({ urls = [], labels = [], open, setOpen,blockImageDialog = false }) => {
	const { t } = useTranslation()
	// const [imageLoading, setImageLoading] = useState(true)
	const [sliderZoomAspectRatios, setSliderZoomAspectRatios] = useState([
		0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8
	])
	const [zoomInDisabled, setZoomInDisabled] = useState(false)
	const [zoomOutDisabled, setZoomOutDisabled] = useState(false)
	const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
	const swiperRef = useRef(null)
	/* const MyTransition = React.forwardRef((props, ref) => (
		<Zoom
			ref={ref}
			{...props}
			enter={true}
			exit={true}
			onExited={() => {
				console.log("I'm here")
			}}
		/>
	)) */

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				disableEscapeKeyDown={true}
				TransitionComponent={TZoom}
				transitionDuration={500}
				PaperProps={{
					sx: {
						background: "transparent"
					}
				}}
				fullScreen
			>
				<DialogContent
					className="image-preview-content"
					sx={{
						padding: "0 !important",
						margin: "0 !important"
					}}
				>
					<div className="close-btn-wrapper right-7 z-10">
						<IconButton
							variant="swiperDialogCloseButton"
							onClick={() => setOpen(false)}
						>
							<i className="bi bi-x" />
						</IconButton>
					</div>
					{/* {imageLoading ? (
						<Skeleton variant="rounded" width={500} height={300} />
					) : (
						<img
							src={`${process.env.REACT_APP_BACKEND_URL}/${url}`}
							alt="image-preview"
							className="h-full w-full"
							loading="lazy"
						/>
					)}
					<img
						src={`${process.env.REACT_APP_BACKEND_URL}/${url}`}
						alt="image-preview"
						className="w-0 h-0"
						onLoad={() => setImageLoading(false)}
						loading="lazy"
					/> */}
					{/* style={{
							"--swiper-navigation-color": "#fff",
							"--swiper-pagination-color": "#fff"
						}} */}
					<Swiper
						lazy="true"
						zoom={{
							toggle: false,
							maxRatio: 1.8,
							minRatio: 0.2
						}}
						navigation={false}
						pagination={false}
						modules={[Zoom, EffectCoverflow]}
						className="w-full h-full"
						speed={500}
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper
						}}
						onSlideChange={(a) => {
							setSliderActiveIndex(a?.activeIndex)
						}}
						effect="coverflow"
					>
						{urls &&
							urls.length > 0 &&
							urls.map((itemUrl, index) => (
								<SwiperSlide key={`swiper-item-${index}`}>
									{labels && labels.length > 0 && labels[index] && (
										<div className="absolute top-4 left-4 flex items-center z-10 text-white text-xl p-3 bg-black bg-opacity-30 rounded-lg font-medium">
											{t("common.table.name")}: {labels[index]}
										</div>
									)}
									<div className="swiper-zoom-container">
										<img
											src={blockImageDialog? itemUrl : `${process.env.REACT_APP_BACKEND_URL}/${itemUrl}`}
											alt=""
											loading="lazy"
										/>
									</div>
									<div className="swiper-lazy-preloader !animate-none !border-none">
										<CircularProgress size={40} sx={{ color: "white" }} />
									</div>
								</SwiperSlide>
							))}

						{/* <SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://images.unsplash.com/photo-1615549781356-fa31834e3c34?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bGFuZHNwYWNlfGVufDB8fDB8fHww" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-2.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-3.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-4.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-5.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-6.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-7.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-8.jpg" />
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="swiper-zoom-container">
								<img src="https://swiperjs.com/demos/images/nature-9.jpg" />
							</div>
						</SwiperSlide> */}
					</Swiper>
					{urls && urls.length > 1 && (
						<Fragment>
							<div
								className={`swiper-navigation-button-wrapper prev${
									sliderActiveIndex === 0 ? " is-disabled" : ""
								}`}
							>
								<IconButton
									variant="swiperDialogNavigationButton"
									type=""
									color="primary"
									onClick={() => {
										swiperRef.current?.slidePrev()
										setZoomInDisabled(false)
										setZoomOutDisabled(false)
									}}
									disabled={sliderActiveIndex === 0}
								>
									<i className="bi bi-chevron-left" />
								</IconButton>
							</div>
							<div
								className={`swiper-navigation-button-wrapper next${
									sliderActiveIndex === urls.length - 1 ? " is-disabled" : ""
								}`}
							>
								<IconButton
									variant="swiperDialogNavigationButton"
									color="primary"
									onClick={() => {
										swiperRef.current?.slideNext()
										setZoomInDisabled(false)
										setZoomOutDisabled(false)
									}}
									disabled={sliderActiveIndex === urls.length - 1}
								>
									<i className="bi bi-chevron-right" />
								</IconButton>
							</div>
						</Fragment>
					)}

					<div className="swiper-zoom-buttons-wrapper">
						<div>
							<IconButton
								variant="swiperDialogZoomIcon"
								color="primary"
								onClick={() => {
									setZoomOutDisabled(false)
									let currIndex =
										sliderZoomAspectRatios.findIndex(
											(item) => item === swiperRef.current?.zoom?.scale
										) + 1
									swiperRef.current?.zoom.in(sliderZoomAspectRatios[currIndex])
									if (currIndex === sliderZoomAspectRatios.length - 1) {
										setZoomInDisabled(true)
									}
								}}
								disabled={zoomInDisabled}
							>
								<i className="bi bi-zoom-in" />
							</IconButton>
							<IconButton
								variant="swiperDialogZoomIcon"
								color="primary"
								onClick={() => {
									setZoomInDisabled(false)
									let currIndex =
										sliderZoomAspectRatios.findIndex(
											(item) => item === swiperRef.current?.zoom?.scale
										) - 1
									swiperRef.current?.zoom.in(sliderZoomAspectRatios[currIndex])
									if (currIndex === 0) {
										setZoomOutDisabled(true)
									}
								}}
								disabled={zoomOutDisabled}
							>
								<i className="bi bi-zoom-out" />
							</IconButton>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default ImagePreviewDialog
