import { TableCell, TableHead, TableRow } from "@mui/material"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"

const SimpleTableHead = (props , {actionModal}) => {
	const { headCells } = props
	const { t } = useTranslation()
	console.log(headCells);
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, headCellIndex) => (
					<Fragment key={"head-cell-" + headCell.code}>
						<TableCell
							align={headCell.numeric || headCell.statusMessage ? "center" : "left"}
							width={headCell.numeric ? "500px": ""}
							padding={headCell.disablePadding ? "none" : "normal"}
						>
							<div dangerouslySetInnerHTML={{ __html: headCell.label }} />
						</TableCell>
					</Fragment>
				))}
			</TableRow>
		
		</TableHead>
	)
}

export default SimpleTableHead
