import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

const ObjectSelectBox = ({
	value = "",
	setValue = () => {},
	size = "medium"
}) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)

	const { data, isLoading, isFetching } = useQuery({
		queryKey: "objectsSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get("/dictionary/objects2")
			return response.data.data
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	return (
		<FormControl color="formColor" fullWidth>
			<Select
				labelId="objects-filter-label"
				label=""
				id="objects-filter-multiple-chip"
				value={value}
				onChange={(event) => setValue(event?.target?.value)}
				color="formColor"
				variant="outlined"
				size={size}
				MenuProps={{
					id: "objectId-select-menu",
					PaperProps: {
						style: {
							maxHeight: 300
						}
					},
					disableScrollLock: true
				}}
			>
				<MenuItem value="ALL" className={size === "small" ? "small-size" : ""}>
					{t("common.fields.allObjects")}
				</MenuItem>
				{isLoading || isFetching ? (
					<div className="circular-progress-box">
						<CircularProgress size={25} />
					</div>
				) : data && data.length > 0 ? (
					data.map((item, index) => (
						<MenuItem
							value={item.id}
							key={`object-${index + 1}`}
							className={size === "small" ? "small-size" : ""}
						>
							{item.name}
						</MenuItem>
					))
				) : (
					<div>
						<span className="no-data-found-wrapper select-box">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</Select>
		</FormControl>
	)
}
export default ObjectSelectBox
