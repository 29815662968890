import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

const BaseTableHead = (props) => {
	const { headCells, filterSort } = props
	const { t } = useTranslation()
	const [order, setOrder] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const handleChange = ()=>{
		setOrder(prev => !prev)
		searchParams.set("sort_by", order?"asc":"desc")
		setSearchParams(searchParams)
	}
	
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, headCellIndex) => (
					<Fragment key={"head-cell-" + headCell.code}>
						<TableCell
							align={headCell?.numeric ? "right" : "left"}
							padding={headCell?.disablePadding ? "none" : "normal"}
							display={"flex"}
						>
							<div dangerouslySetInnerHTML={{ __html: headCell.label }} style={{display: "inline-block"}} />
							{filterSort && headCellIndex == 0 &&
							<TableSortLabel 
							active={headCellIndex == 0 && true}
							direction={order? 'desc' : 'asc'}
							onClick={()=> handleChange() }
             
            >

            </TableSortLabel>}
						</TableCell>
					</Fragment>
				))}
				<TableCell padding="normal" align="right">
					{t("common.table.actions")}
				</TableCell>
			</TableRow>
		</TableHead>
	)
}

export default BaseTableHead
