import { CircularProgress, Grid, Switch } from '@mui/material'
import React, { Fragment, useState } from 'react'
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useQueries } from 'react-query'
import { useTranslation } from 'react-i18next'
const MessageSending = () => {
    const [hasError, setHasError] = useState(false)
    const sendNotification = useNotification()
    const axiosPrivate = useAxiosPrivate()
    const { t } = useTranslation()
    const [messageDetailsQuery, messageTemplateListQuery, messageHeadTypeQuery, messageTagsQuery] = useQueries([
    
		{
			queryKey: "messageDetails",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_text_templates")
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "messageTemplateList",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_text_templates")
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
    {
			queryKey: "messageHeadType",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_types")
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
    ,
    {
			queryKey: "messageTags",
			queryFn: async function () {
				const response = await axiosPrivate.get("sms_text_template_tags")
				return response.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

    const handleChangeAutoMessageSend = async (value, templateId) => {
		try {
			const response = await axiosPrivate.get(
				`sms_text_templates/${templateId}/toggle`,
				JSON.stringify({ id: templateId, is_active: value}),
				{ headers: { "Content-Type": "application/json" } }
				
			)
			if (response.data) {
				messageDetailsQuery.refetch()
                sendNotification({
                msg: t("settings.message.serverDetailsAddEditModal.alerts.update"),
                variant: "success"})
			    }
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
		}
	}

  return (
    <div className="bg-[#fff] p-5 rounded-[7px]" style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
    <Grid
        container
        spacing={{ xs: 2, sm: 3, lg: 3 }}
        rowSpacing={3}
        columns={{ xs: 12, sm: 12, lg: 12 }}
		style={{
			marginTop: "-8px"
		}}
        >
        {messageDetailsQuery && (
        <Fragment>
              { messageDetailsQuery.isLoading?
                <div className="flex items-center w-full h-full justify-center">
                  <CircularProgress size={27.5} color="inherit" />
                </div>:
                messageDetailsQuery?.data && messageDetailsQuery?.data.length > 0 ?(
                messageDetailsQuery?.data.map((item,index)=>(
        <Grid item={true} lg={4} sm={6} xs={12} key={index+"sending_message_setting"}>
            <div className="border p-3" style={{borderRadius: '5px',borderColor:"rgb(156 163 175)"}}>
                <div className='flex items-start justify-between'>
                    <div style={{
                        fontWeight: "600",
                        paddingTop: "8px"
                    }}>{messageHeadTypeQuery?.data?.filter(type=> type.type === item?.type).map(set=> set.text)}</div>
                    <div>
                        <Switch
                            checked={item?.is_active}
                            onChange={(event)=>{
                                handleChangeAutoMessageSend(
                                    event?.target?.checked,
									item?.id
                                )
                            }}
                        >

                        </Switch>
                    </div>
                </div>
                <div style={{
                        fontWeight: "400",
                        color: "rgb(156 163 175)",
                        marginTop: '6px'
                    }}>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum ea temporibus impedit.</p>
                </div>
            </div>
        </Grid>
            ))):
			<span className="no-data-found-wrapper mt-2 flex justify-center w-full items-center h-full">
				<i className="bi bi-exclamation-octagon icon-xl" />{" "}
				{t("common.global.noDataFound")}
			</span>
			}
        </Fragment>
    )}
    </Grid>
    </div>
  )
}

export default MessageSending