/* const formatMultiplies = (...numbers) => {
	if (numbers.length > 0) {
		numbers = numbers.map((item) =>
			typeof item === "number" ? item.toString() : item
		)
		let withFloatNumber = numbers.reduce(
			(acc, curr) => acc || curr.includes("."),
			false
		)
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? parseInt(
					numbers.reduce((acc, curr) => {
						let x = (
							((acc * parseFloat(curr).toFixed(fixedLength) * fixedL) /
								fixedL) *
							fixedL
						).toFixed(fixedLength)
						// console.log("x = ", x)
						// let c = parseFloat(curr)
						// let c1 = acc * c * fixedL
						// let m1 = parseInt(c1 / fixedL)
						// let x = m1 * fixedL
						// console.log("c = ", c)
						// console.log("c1 = ", c1)
						// console.log("m1 = ", m1)
						// console.log("x = ", x)
						// return x
						// return x
					}, 1)
			  ) / Math.pow(fixedL, numbers.length)
			: Math.round(
					parseInt(
						numbers.reduce((acc, curr) => {
							return (
								Math.round((acc * parseFloat(curr) * fixedL) / fixedL) * fixedL
							)
						}, 1)
					) / Math.pow(fixedL, numbers.length)
			  )
	}
	return 0
}

const formatSingle = (number) => {
	if(!isNaN(number)) {
		return parseInt(parseFloat(number) * 1000) / 1000
	}
	return 0
}

export {formatMultiplies, formatSingle} */
const formatMultiplies = (numbers = [], fixedLength = 1) => {
	if (numbers.length >= 0) {
		numbers = numbers.map((item) =>
			typeof item === "number" ? item.toString() : item
		)
		let withFloatNumber = numbers.reduce(
			(acc, curr) => acc || curr.includes("."),
			false
		)
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? parseInt(
					numbers.reduce((acc, curr) => {
						return (
							((acc * parseFloat(curr).toFixed(fixedLength) * fixedL) /
								fixedL) *
							fixedL
						).toFixed(fixedLength)
					}, 1)
			  ) / Math.pow(fixedL, numbers.length)
			: Math.round(
					parseInt(
						numbers.reduce((acc, curr) => {
							return (
								Math.round((acc * parseFloat(curr) * fixedL) / fixedL) * fixedL
							)
						}, 1)
					) / Math.pow(fixedL, numbers.length)
			  )
	}
	return 0
}

const formatSingle = (number, fixedLength = 1) => {
	/* if (!isNaN(number)) {
		let currNumber =
			typeof number === "number"
				? number.toFixed(fixedLength)
				: parseFloat(number.toString()).toFixed(fixedLength)
		let withFloatNumber = currNumber.includes(".")
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? parseInt(parseFloat(currNumber) * fixedL) / fixedL
			: Math.round(parseInt(parseFloat(currNumber) * fixedL) / fixedL)
	} */

	if (!isNaN(number)) {
		let currNumber = number.toFixed(fixedLength)
		let withFloatNumber = currNumber.includes(".")
		let fixedL = Math.pow(10, fixedLength)
		return withFloatNumber
			? /* parseFloat(
					(parseInt(parseFloat(currNumber) * fixedL) / fixedL).toFixed(
						fixedLength
					)
			  ) */
			  parseInt(parseFloat(currNumber) * fixedL) / fixedL
			: Math.round(parseInt(parseFloat(currNumber) * fixedL) / fixedL)
	}
	return 0
}

export { formatMultiplies, formatSingle }
