import { Button, CircularProgress } from "@mui/material"
import ObjectSelectBox from "components/boss/ObjectSelectBox"
import BarChart from "components/ui/charts/BarChart"
import SimpleToggleButtonGroup from "components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { numericFormatter } from "react-number-format"
import { useQuery } from "react-query"
import { colors } from "shared/colors"
// import "locale/uz-latn"
// import "locale/ru"
// import "locale/uz"
import useCurrency from "hooks/useCurrency"
import { barTooltip } from "utils/chartjsTooltips"

const Arrears = () => {
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()
	const axiosPrivate = useAxiosPrivate()
	// const [hasError, setHasError] = useState(false)
	const { currencyData } = useCurrency()
	const [debtorsData, setDebtorsData] = useState([])
	const [selectedObjectId, setSelectedObjectId] = useState("ALL")
	const [dateType, setDateType] = useState("DAILY")
	const [dateTypes, setDateTypes] = useState([
		{
			code: "DAILY",
			label: "Kunlik",
			parserCode: "months",
			isFourYears: false
		},
		{ code: "MONTH", label: "Oylik", parserCode: "years", isFourYears: false },
		{ code: "YEAR", label: "Yillik", parserCode: "years", isFourYears: true }
	])
	const [startAndFinishDates, setStartAndFinishDates] = useState({
		startDate: moment().startOf("months").format("YYYY-MM-DD"),
		finishDate: moment().endOf("months").format("YYYY-MM-DD")
	})
	const localeMoment = moment

	const { isLoading, isFetching, isError } = useQuery({
		queryKey: ["analyticDebtors", startAndFinishDates, selectedObjectId],
		queryFn: async function () {
			const response = await axiosPrivate.get(
				`/analytic/debitors/index?type=${dateType}&start=${
					startAndFinishDates.startDate
				}&finish=${startAndFinishDates.finishDate}${
					selectedObjectId !== "ALL" ? `&objects[0]=${selectedObjectId}` : ""
				}`
			)
			return response.data.data
		},
		onSuccess: (data) => {
			if (data && data.length > 0) {
				setDebtorsData([...data])
			}
		},
		// enabled: !hasError,
		// onError: (error) => {
		// 	setHasError(true)
		// },
		retry: false
	})

	const handleDateType = (value) => {
		setDateType(value)
		let currDateType = dateTypes.find((item) => item.code === value)

		if (currDateType.isFourYears) {
			setStartAndFinishDates({
				startDate: moment()
					.subtract(4, currDateType.parserCode)
					.startOf(currDateType.parserCode)
					.format("YYYY-MM-DD"),
				finishDate: moment().endOf(currDateType.parserCode).format("YYYY-MM-DD")
			})
		} else {
			setStartAndFinishDates({
				startDate: moment()
					.startOf(currDateType.parserCode)
					.format("YYYY-MM-DD"),
				finishDate: moment().endOf(currDateType.parserCode).format("YYYY-MM-DD")
			})
		}
	}

	const handlePrevMonth = () => {
		let currDateType = dateTypes.find((item) => item.code === dateType)

		let subtractNumber = currDateType.isFourYears ? 4 : 1

		let newStartDate = moment(startAndFinishDates.startDate, "YYYY-MM-DD")
			.subtract(subtractNumber, currDateType.parserCode)
			.startOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		let newFinishDate = moment(startAndFinishDates.finishDate, "YYYY-MM-DD")
			.subtract(subtractNumber, currDateType.parserCode)
			.endOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		setStartAndFinishDates({
			startDate: newStartDate,
			finishDate: newFinishDate
		})
	}

	const handleNextMonth = () => {
		let currDateType = dateTypes.find((item) => item.code === dateType)

		let addNumber = currDateType.isFourYears ? 4 : 1

		let newStartDate = moment(startAndFinishDates.startDate, "YYYY-MM-DD")
			.add(addNumber, currDateType.parserCode)
			.startOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		let newFinishDate = moment(startAndFinishDates.finishDate, "YYYY-MM-DD")
			.add(addNumber, currDateType.parserCode)
			.endOf(currDateType.parserCode)
			.format("YYYY-MM-DD")

		setStartAndFinishDates({
			startDate: newStartDate,
			finishDate: newFinishDate
		})
	}

	useEffect(() => {
		setComponent(
			<div className="component-title">{t("boss.arrears.title")}</div>
		)
		localeMoment.locale(i18n.language === "uz" ? "uz-latn" : "uz")
	}, [i18n])

	return (
		<div className="component-wrapper boss-arrears-wrapper">
			<div className="arrears-actions-wrapper">
				<div className="arrears-actions-body">
					<div className="flex items-center">
						<div className="min-w-[250px]">
							<SimpleToggleButtonGroup
								delay={0}
								duration={0}
								value={dateType}
								changeFn={handleDateType}
								options={dateTypes}
								size="small"
								fullWidth={true}
							/>
						</div>
						<div className="w-full ml-4">
							<ObjectSelectBox
								value={selectedObjectId}
								setValue={setSelectedObjectId}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="arrears-chart">
				<div className="chart-header">
					<Button
						variant="action"
						color="secondary"
						onClick={() => handlePrevMonth()}
						disabled={isLoading || isFetching}
					>
						<i className="bi bi-chevron-left" />
					</Button>
					<div className="chart-title">
						{dateType === "DAILY" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).format("MMMM")}, `}
						{dateType !== "YEAR" &&
							localeMoment(startAndFinishDates.startDate, "YYYY-MM-DD").year()}
						{dateType === "YEAR" &&
							`${localeMoment(
								startAndFinishDates.startDate,
								"YYYY-MM-DD"
							).year()} - ${localeMoment(
								startAndFinishDates.finishDate,
								"YYYY-MM-DD"
							).year()}`}
					</div>
					<Button
						variant="action"
						color="secondary"
						onClick={() => handleNextMonth()}
						disabled={isLoading || isFetching}
					>
						<i className="bi bi-chevron-right" />
					</Button>
				</div>
				{isError ? (
					<div>
						<span className="no-data-found-wrapper">
							<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				) : debtorsData.length > 0 ? (
					<div className="h-[300px]">
						<BarChart
							chartLabels={
								dateType === "DAILY"
									? [...debtorsData].map((item) =>
											localeMoment(item.date, "YYYY-MM-DD").format("DD")
									  )
									: dateType === "MONTH"
									? [...debtorsData].map((item) =>
											localeMoment(item.date, "YYYY-MM-DD").format("MMMM")
									  )
									: [...debtorsData].map((item) =>
											localeMoment(item.date, "YYYY-MM-DD").format("YYYY")
									  )
							}
							chartDatasets={[
								{
									data: [...debtorsData].map((item) => ({
										total:
											+item.sum +
											(item?.valute ? +item.valute * currencyData?.sum : 0),
										sum: item.sum,
										valute: item?.valute ? item.valute : 0
									})),
									borderRadius: 4,
									borderWidth: 2,
									borderColor: colors.baseColorLight,
									backgroundColor: colors.baseColorOutline,
									hoverBackgroundColor: colors.baseColorLight,
									tension: 0.3
								}
							]}
							optionPlugins={{
								tooltip: {
									enabled: false,
									external: barTooltip("sum", "valute", "total")
								}
							}}
							options={{
								scales: {
									y: {
										ticks: {
											callback: function (value) {
												let isTrillion =
													parseFloat(value) / Math.pow(10, 12) >= 1
												let isBillion = parseFloat(value) / Math.pow(10, 9) >= 1
												return Math.floor(value) > 1
													? numericFormatter(
															isTrillion
																? (
																		parseFloat(value) / Math.pow(10, 12)
																  ).toString()
																: isBillion
																? (
																		parseFloat(value) / Math.pow(10, 9)
																  ).toString()
																: (
																		parseFloat(value) / Math.pow(10, 6)
																  ).toString(),
															{
																decimalScale: 3,
																thousandSeparator: " ",
																allowNegative: false,
																suffix: isTrillion
																	? " trln UZS"
																	: isBillion
																	? " mlrd UZS"
																	: " mln UZS"
															}
													  )
													: value
											}
										}
									}
								},
								parsing: {
									xAxisKey: "total",
									yAxisKey: "total"
								}
							}}
						/>
					</div>
				) : (
					<div className="h-[300px] flex items-center justify-center">
						<CircularProgress size={30} color="inherit" />
					</div>
				)}
			</div>
			<div className="arrears-table"></div>
		</div>
	)
}
export default Arrears
