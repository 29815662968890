import React, { useEffect } from "react"
import image404 from "assets/images/errors/404.svg"
import usePrevNext from "hooks/usePrevNext"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import useTopPanel from "hooks/useTopPanel"

const NotFoundPage = () => {
	const { prev } = usePrevNext()
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("common.errorPage.notFound.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<div className="not-found-page flex items-center justify-center flex-col h-full overflow-y-auto">
			<img
				src={image404}
				alt="Page Not Found"
				className="sm:w-[600px] w-[400px] sm:px-10 px-5"
			/>
			<Button
				color="primary"
				variant="contained"
				className="!-mt-4 lg:!text-xl font-medium px-2 py-1 animate-[bounce_2s_infinite]"
				onClick={() => prev()}
			>
				{t("common.errorPage.notFound.goBack")}
			</Button>
		</div>
	)
}

export default NotFoundPage
